.pr-review-engine .pr-snapshot-title, .pr-review-engine .pr-base-modal .pr-profile-nickname,
.pr-base-modal .pr-profile-nickname, .pr-base-modal .pr-review-rating-headline,
.pr-review-engine .pr-review-rating-headline, .pr-review-engine .pr-base-modal .pr-fb-follow-modal-header,
.pr-base-modal .pr-fb-follow-modal-header, .pr-review-engine .pr-review-faceoff-title, .pr-review-engine .pr-base-modal .pr-profile-reviews-header,
.pr-base-modal .pr-profile-reviews-header, .pr-base-modal .pr-attribute-label-pros,
.pr-base-modal .pr-attribute-label-cons,
.pr-base-modal .pr-attribute-label-bestuses,
.pr-base-modal .pr-attribute-label-rating,
.pr-review-engine .pr-attribute-label-pros,
.pr-review-engine .pr-attribute-label-cons,
.pr-review-engine .pr-attribute-label-bestuses,
.pr-review-engine .pr-attribute-label-rating, .pr-review-engine #pr-snapshot-histogram-header, .pr-review-engine .pr-snapshot-consensus-value, .pr-review-engine .pr-review-faceoff-img-txt, .pr-review-engine .pr-review-count, .pr-review-engine .pr-base-modal .pr-profile-histogram-header,
.pr-base-modal .pr-profile-histogram-header, .pr-review-engine .pr-base-modal .pr-review-author-affinities,
.pr-base-modal .pr-review-author-affinities, .pr-base-modal .pr-review-author-name,
.pr-base-modal .pr-review-author-location,
.pr-review-engine .pr-review-author-name,
.pr-review-engine .pr-review-author-location,
.pr-review-engine .pr-review-author-affinities, .pr-base-modal .pr-caption,
.pr-review-engine .pr-caption, .pr-base-modal .pr-review-bottom-line,
.pr-review-engine .pr-review-bottom-line, .pr-base-modal .pr-other-attribute-label,
.pr-review-engine .pr-other-attribute-label, .pr-review-engine .pr-snapshot-fb-get-advice-label,
.pr-review-engine .pr-snapshot-fb-follow-product-category-label, .pr-review-engine .pr-snapshot-average-based-on-text, .pr-review-engine .pr-review-faceoff-label, .pr-review-engine .pr-review-sort-by {
  font-weight: bold;
  color: #303533;
  line-height: 1.2;
}

.pr-review-engine .pr-snapshot-title, .pr-review-engine .pr-base-modal .pr-profile-nickname,
.pr-base-modal .pr-profile-nickname {
  font-size: 22px;
}

.pr-base-modal .pr-review-rating-headline,
.pr-review-engine .pr-review-rating-headline, .pr-review-engine .pr-base-modal .pr-fb-follow-modal-header,
.pr-base-modal .pr-fb-follow-modal-header {
  font-size: 20px;
}

.pr-review-engine .pr-review-faceoff-title, .pr-review-engine .pr-base-modal .pr-profile-reviews-header,
.pr-base-modal .pr-profile-reviews-header {
  font-size: 18px;
}

.pr-base-modal .pr-attribute-label-pros,
.pr-base-modal .pr-attribute-label-cons,
.pr-base-modal .pr-attribute-label-bestuses,
.pr-base-modal .pr-attribute-label-rating,
.pr-review-engine .pr-attribute-label-pros,
.pr-review-engine .pr-attribute-label-cons,
.pr-review-engine .pr-attribute-label-bestuses,
.pr-review-engine .pr-attribute-label-rating, .pr-review-engine #pr-snapshot-histogram-header, .pr-review-engine .pr-snapshot-consensus-value, .pr-review-engine .pr-review-faceoff-img-txt, .pr-review-engine .pr-review-count, .pr-review-engine .pr-base-modal .pr-profile-histogram-header,
.pr-base-modal .pr-profile-histogram-header, .pr-review-engine .pr-base-modal .pr-review-author-affinities,
.pr-base-modal .pr-review-author-affinities {
  font-size: 16px;
}

.pr-base-modal .pr-review-author-name,
.pr-base-modal .pr-review-author-location,
.pr-base-modal .pr-review-author-affinities,
.pr-review-engine .pr-review-author-name,
.pr-review-engine .pr-review-author-location,
.pr-review-engine .pr-review-author-affinities, .pr-base-modal .pr-caption,
.pr-review-engine .pr-caption, .pr-base-modal .pr-review-bottom-line,
.pr-review-engine .pr-review-bottom-line, .pr-base-modal .pr-other-attribute-label,
.pr-review-engine .pr-other-attribute-label, .pr-review-engine .pr-snapshot-fb-get-advice-label,
.pr-review-engine .pr-snapshot-fb-follow-product-category-label, .pr-review-engine .pr-snapshot-average-based-on-text, .pr-review-engine .pr-review-faceoff-label, .pr-review-engine .pr-review-sort-by {
  font-size: 14px;
}

.pr-review-engine .pr-snapshot-consensus-text, .pr-review-engine .pr-review-most-helpful p, .pr-snippet .pr-snippet-rating-decimal, .pr-snippet .pr-snippet-review-count, .pr-snippet .pr-snippet-read-reviews,
.pr-snippet .pr-snippet-write-review, .pr-pa-snippet .pr-pa-snippet-answers,
.pr-pa-snippet .pr-pa-snippet-questions, .pr-pa-snippet .pr-pa-snippet-text, .pr-base-modal p,
.pr-base-modal ul,
.pr-base-modal li,
.pr-review-engine p,
.pr-review-engine ul,
.pr-review-engine li,
.pr-snippet p,
.pr-snippet ul,
.pr-snippet li,
.pr-pa-snippet p,
.pr-pa-snippet ul,
.pr-pa-snippet li,
.prImageSnippet p,
.prImageSnippet ul,
.prImageSnippet li, .pr-base-modal .pr-rating,
.pr-base-modal .pr-review-author-date,
.pr-review-engine .pr-rating,
.pr-review-engine .pr-review-author-date, .pr-base-modal .pr-attribute-value-list li,
.pr-review-engine .pr-attribute-value-list li, .pr-review-engine .pr-snapshot-fb-get-advice-link,
.pr-review-engine .pr-snapshot-fb-follow-product-category-link, .pr-review-engine .pr-review-sort-box select, .pr-review-engine-min-width-560px-max-width-690px .pr-review-author-location, .pr-base-modal .pr-comments,
.pr-review-engine .pr-comments, .pr-review-engine .pr-base-modal .pr-profile-location,
.pr-base-modal .pr-profile-location, .pr-review-engine .pr-base-modal .pr-review-author-affinities span,
.pr-base-modal .pr-review-author-affinities span {
  color: #666666;
  line-height: 1.5;
}

.pr-review-engine .pr-snapshot-consensus-text, .pr-review-engine .pr-review-most-helpful p, .pr-snippet .pr-snippet-rating-decimal, .pr-snippet .pr-snippet-review-count, .pr-snippet .pr-snippet-read-reviews,
.pr-snippet .pr-snippet-write-review, .pr-pa-snippet .pr-pa-snippet-answers,
.pr-pa-snippet .pr-pa-snippet-questions, .pr-pa-snippet .pr-pa-snippet-text {
  font-size: 12px;
}

.pr-base-modal p,
.pr-base-modal ul,
.pr-base-modal li,
.pr-review-engine p,
.pr-review-engine ul,
.pr-review-engine li,
.pr-snippet p,
.pr-snippet ul,
.pr-snippet li,
.pr-pa-snippet p,
.pr-pa-snippet ul,
.pr-pa-snippet li,
.prImageSnippet p,
.prImageSnippet ul,
.prImageSnippet li, .pr-base-modal .pr-rating,
.pr-base-modal .pr-review-author-date,
.pr-review-engine .pr-rating,
.pr-review-engine .pr-review-author-date, .pr-base-modal .pr-attribute-value-list li,
.pr-review-engine .pr-attribute-value-list li, .pr-review-engine .pr-snapshot-fb-get-advice-link,
.pr-review-engine .pr-snapshot-fb-follow-product-category-link, .pr-review-engine .pr-review-sort-box select, .pr-review-engine-min-width-560px-max-width-690px .pr-review-author-location {
  font-size: 14px;
}

.pr-base-modal .pr-comments,
.pr-review-engine .pr-comments, .pr-review-engine .pr-base-modal .pr-profile-location,
.pr-base-modal .pr-profile-location, .pr-review-engine .pr-base-modal .pr-review-author-affinities span,
.pr-base-modal .pr-review-author-affinities span {
  font-size: 16px;
}


.pr-base-modal .pr-other-attribute-value-histogram-barValue,
.pr-review-engine .pr-other-attribute-value-histogram-barValue,
.pr-review-engine .pr-ratings-histogram-barValue,
.pr-review-engine #pr-snapshot-histogram-container .pr-ratings-histogram-barValue,
.pr-review-engine .pr-base-modal .pr-ratings-histogram-barValue,
.pr-base-modal .pr-ratings-histogram-barValue,
.pr-summary-snippet .pr-other-attribute-value-histogram-barValue {
  border-radius: 2px;
  background: #f7941d;
}


.pr-base-modal .pr-other-attribute-value-histogram-wrapper,
.pr-review-engine .pr-other-attribute-value-histogram-wrapper,
.pr-review-engine .pr-ratings-histogram-bar,
.pr-review-engine #pr-snapshot-histogram-container .pr-ratings-histogram-bar,
.pr-review-engine .pr-base-modal .pr-ratings-histogram-bar,
.pr-base-modal .pr-ratings-histogram-bar,
.pr-summary-snippet .pr-other-attribute-value-histogram-wrapper {
  display: inline-block;
  border-radius: 2px;
  vertical-align: middle;
  background: #f6f6f6;
}


.pr-base-modal .pr-other-attribute-value-histogram-barValue,
.pr-review-engine .pr-other-attribute-value-histogram-barValue,
.pr-review-engine .pr-ratings-histogram-barValue,
.pr-review-engine #pr-snapshot-histogram-container .pr-ratings-histogram-barValue,
.pr-review-engine .pr-base-modal .pr-ratings-histogram-barValue,
.pr-base-modal .pr-ratings-histogram-barValue,
.pr-summary-snippet .pr-other-attribute-value-histogram-barValue {
  height: 10px;
}


.pr-base-modal .pr-other-attribute-value-histogram-wrapper,
.pr-review-engine .pr-other-attribute-value-histogram-wrapper,
.pr-review-engine .pr-ratings-histogram-bar,
.pr-review-engine #pr-snapshot-histogram-container .pr-ratings-histogram-bar,
.pr-review-engine .pr-base-modal .pr-ratings-histogram-bar,
.pr-base-modal .pr-ratings-histogram-bar,
.pr-summary-snippet .pr-other-attribute-value-histogram-wrapper {
  height: 10px;
}

.pr-base-modal,
.pr-review-engine,
.pr-snippet,
.pr-pa-snippet,
.prImageSnippet {
  font-family: arial, sans-serif;
}

.pr-base-modal html,
.pr-base-modal body,
.pr-base-modal div,
.pr-base-modal span,
.pr-base-modal applet,
.pr-base-modal object,
.pr-base-modal iframe,
.pr-base-modal h1,
.pr-base-modal h2,
.pr-base-modal h3,
.pr-base-modal h4,
.pr-base-modal h5,
.pr-base-modal h6,
.pr-base-modal p,
.pr-base-modal blockquote,
.pr-base-modal pre,
.pr-base-modal a,
.pr-base-modal abbr,
.pr-base-modal acronym,
.pr-base-modal address,
.pr-base-modal big,
.pr-base-modal cite,
.pr-base-modal code,
.pr-base-modal del,
.pr-base-modal dfn,
.pr-base-modal em,
.pr-base-modal img,
.pr-base-modal ins,
.pr-base-modal kbd,
.pr-base-modal q,
.pr-base-modal s,
.pr-base-modal samp,
.pr-base-modal small,
.pr-base-modal strike,
.pr-base-modal strong,
.pr-base-modal sub,
.pr-base-modal sup,
.pr-base-modal tt,
.pr-base-modal var,
.pr-base-modal b,
.pr-base-modal u,
.pr-base-modal i,
.pr-base-modal center,
.pr-base-modal dl,
.pr-base-modal dt,
.pr-base-modal dd,
.pr-base-modal ol,
.pr-base-modal ul,
.pr-base-modal li,
.pr-base-modal fieldset,
.pr-base-modal form,
.pr-base-modal label,
.pr-base-modal legend,
.pr-base-modal table,
.pr-base-modal caption,
.pr-base-modal tbody,
.pr-base-modal tfoot,
.pr-base-modal thead,
.pr-base-modal tr,
.pr-base-modal th,
.pr-base-modal td,
.pr-base-modal article,
.pr-base-modal aside,
.pr-base-modal canvas,
.pr-base-modal details,
.pr-base-modal embed,
.pr-base-modal figure,
.pr-base-modal figcaption,
.pr-base-modal footer,
.pr-base-modal header,
.pr-base-modal hgroup,
.pr-base-modal menu,
.pr-base-modal nav,
.pr-base-modal output,
.pr-base-modal ruby,
.pr-base-modal section,
.pr-base-modal summary,
.pr-base-modal time,
.pr-base-modal mark,
.pr-base-modal audio,
.pr-base-modal video,
.pr-review-engine html,
.pr-review-engine body,
.pr-review-engine div,
.pr-review-engine span,
.pr-review-engine applet,
.pr-review-engine object,
.pr-review-engine iframe,
.pr-review-engine h1,
.pr-review-engine h2,
.pr-review-engine h3,
.pr-review-engine h4,
.pr-review-engine h5,
.pr-review-engine h6,
.pr-review-engine p,
.pr-review-engine blockquote,
.pr-review-engine pre,
.pr-review-engine a,
.pr-review-engine abbr,
.pr-review-engine acronym,
.pr-review-engine address,
.pr-review-engine big,
.pr-review-engine cite,
.pr-review-engine code,
.pr-review-engine del,
.pr-review-engine dfn,
.pr-review-engine em,
.pr-review-engine img,
.pr-review-engine ins,
.pr-review-engine kbd,
.pr-review-engine q,
.pr-review-engine s,
.pr-review-engine samp,
.pr-review-engine small,
.pr-review-engine strike,
.pr-review-engine strong,
.pr-review-engine sub,
.pr-review-engine sup,
.pr-review-engine tt,
.pr-review-engine var,
.pr-review-engine b,
.pr-review-engine u,
.pr-review-engine i,
.pr-review-engine center,
.pr-review-engine dl,
.pr-review-engine dt,
.pr-review-engine dd,
.pr-review-engine ol,
.pr-review-engine ul,
.pr-review-engine li,
.pr-review-engine fieldset,
.pr-review-engine form,
.pr-review-engine label,
.pr-review-engine legend,
.pr-review-engine table,
.pr-review-engine caption,
.pr-review-engine tbody,
.pr-review-engine tfoot,
.pr-review-engine thead,
.pr-review-engine tr,
.pr-review-engine th,
.pr-review-engine td,
.pr-review-engine article,
.pr-review-engine aside,
.pr-review-engine canvas,
.pr-review-engine details,
.pr-review-engine embed,
.pr-review-engine figure,
.pr-review-engine figcaption,
.pr-review-engine footer,
.pr-review-engine header,
.pr-review-engine hgroup,
.pr-review-engine menu,
.pr-review-engine nav,
.pr-review-engine output,
.pr-review-engine ruby,
.pr-review-engine section,
.pr-review-engine summary,
.pr-review-engine time,
.pr-review-engine mark,
.pr-review-engine audio,
.pr-review-engine video,
.pr-snippet html,
.pr-snippet body,
.pr-snippet div,
.pr-snippet span,
.pr-snippet applet,
.pr-snippet object,
.pr-snippet iframe,
.pr-snippet h1,
.pr-snippet h2,
.pr-snippet h3,
.pr-snippet h4,
.pr-snippet h5,
.pr-snippet h6,
.pr-snippet p,
.pr-snippet blockquote,
.pr-snippet pre,
.pr-snippet a,
.pr-snippet abbr,
.pr-snippet acronym,
.pr-snippet address,
.pr-snippet big,
.pr-snippet cite,
.pr-snippet code,
.pr-snippet del,
.pr-snippet dfn,
.pr-snippet em,
.pr-snippet img,
.pr-snippet ins,
.pr-snippet kbd,
.pr-snippet q,
.pr-snippet s,
.pr-snippet samp,
.pr-snippet small,
.pr-snippet strike,
.pr-snippet strong,
.pr-snippet sub,
.pr-snippet sup,
.pr-snippet tt,
.pr-snippet var,
.pr-snippet b,
.pr-snippet u,
.pr-snippet i,
.pr-snippet center,
.pr-snippet dl,
.pr-snippet dt,
.pr-snippet dd,
.pr-snippet ol,
.pr-snippet ul,
.pr-snippet li,
.pr-snippet fieldset,
.pr-snippet form,
.pr-snippet label,
.pr-snippet legend,
.pr-snippet table,
.pr-snippet caption,
.pr-snippet tbody,
.pr-snippet tfoot,
.pr-snippet thead,
.pr-snippet tr,
.pr-snippet th,
.pr-snippet td,
.pr-snippet article,
.pr-snippet aside,
.pr-snippet canvas,
.pr-snippet details,
.pr-snippet embed,
.pr-snippet figure,
.pr-snippet figcaption,
.pr-snippet footer,
.pr-snippet header,
.pr-snippet hgroup,
.pr-snippet menu,
.pr-snippet nav,
.pr-snippet output,
.pr-snippet ruby,
.pr-snippet section,
.pr-snippet summary,
.pr-snippet time,
.pr-snippet mark,
.pr-snippet audio,
.pr-snippet video,
.pr-pa-snippet html,
.pr-pa-snippet body,
.pr-pa-snippet div,
.pr-pa-snippet span,
.pr-pa-snippet applet,
.pr-pa-snippet object,
.pr-pa-snippet iframe,
.pr-pa-snippet h1,
.pr-pa-snippet h2,
.pr-pa-snippet h3,
.pr-pa-snippet h4,
.pr-pa-snippet h5,
.pr-pa-snippet h6,
.pr-pa-snippet p,
.pr-pa-snippet blockquote,
.pr-pa-snippet pre,
.pr-pa-snippet a,
.pr-pa-snippet abbr,
.pr-pa-snippet acronym,
.pr-pa-snippet address,
.pr-pa-snippet big,
.pr-pa-snippet cite,
.pr-pa-snippet code,
.pr-pa-snippet del,
.pr-pa-snippet dfn,
.pr-pa-snippet em,
.pr-pa-snippet img,
.pr-pa-snippet ins,
.pr-pa-snippet kbd,
.pr-pa-snippet q,
.pr-pa-snippet s,
.pr-pa-snippet samp,
.pr-pa-snippet small,
.pr-pa-snippet strike,
.pr-pa-snippet strong,
.pr-pa-snippet sub,
.pr-pa-snippet sup,
.pr-pa-snippet tt,
.pr-pa-snippet var,
.pr-pa-snippet b,
.pr-pa-snippet u,
.pr-pa-snippet i,
.pr-pa-snippet center,
.pr-pa-snippet dl,
.pr-pa-snippet dt,
.pr-pa-snippet dd,
.pr-pa-snippet ol,
.pr-pa-snippet ul,
.pr-pa-snippet li,
.pr-pa-snippet fieldset,
.pr-pa-snippet form,
.pr-pa-snippet label,
.pr-pa-snippet legend,
.pr-pa-snippet table,
.pr-pa-snippet caption,
.pr-pa-snippet tbody,
.pr-pa-snippet tfoot,
.pr-pa-snippet thead,
.pr-pa-snippet tr,
.pr-pa-snippet th,
.pr-pa-snippet td,
.pr-pa-snippet article,
.pr-pa-snippet aside,
.pr-pa-snippet canvas,
.pr-pa-snippet details,
.pr-pa-snippet embed,
.pr-pa-snippet figure,
.pr-pa-snippet figcaption,
.pr-pa-snippet footer,
.pr-pa-snippet header,
.pr-pa-snippet hgroup,
.pr-pa-snippet menu,
.pr-pa-snippet nav,
.pr-pa-snippet output,
.pr-pa-snippet ruby,
.pr-pa-snippet section,
.pr-pa-snippet summary,
.pr-pa-snippet time,
.pr-pa-snippet mark,
.pr-pa-snippet audio,
.pr-pa-snippet video,
.prImageSnippet html,
.prImageSnippet body,
.prImageSnippet div,
.prImageSnippet span,
.prImageSnippet applet,
.prImageSnippet object,
.prImageSnippet iframe,
.prImageSnippet h1,
.prImageSnippet h2,
.prImageSnippet h3,
.prImageSnippet h4,
.prImageSnippet h5,
.prImageSnippet h6,
.prImageSnippet p,
.prImageSnippet blockquote,
.prImageSnippet pre,
.prImageSnippet a,
.prImageSnippet abbr,
.prImageSnippet acronym,
.prImageSnippet address,
.prImageSnippet big,
.prImageSnippet cite,
.prImageSnippet code,
.prImageSnippet del,
.prImageSnippet dfn,
.prImageSnippet em,
.prImageSnippet img,
.prImageSnippet ins,
.prImageSnippet kbd,
.prImageSnippet q,
.prImageSnippet s,
.prImageSnippet samp,
.prImageSnippet small,
.prImageSnippet strike,
.prImageSnippet strong,
.prImageSnippet sub,
.prImageSnippet sup,
.prImageSnippet tt,
.prImageSnippet var,
.prImageSnippet b,
.prImageSnippet u,
.prImageSnippet i,
.prImageSnippet center,
.prImageSnippet dl,
.prImageSnippet dt,
.prImageSnippet dd,
.prImageSnippet ol,
.prImageSnippet ul,
.prImageSnippet li,
.prImageSnippet fieldset,
.prImageSnippet form,
.prImageSnippet label,
.prImageSnippet legend,
.prImageSnippet table,
.prImageSnippet caption,
.prImageSnippet tbody,
.prImageSnippet tfoot,
.prImageSnippet thead,
.prImageSnippet tr,
.prImageSnippet th,
.prImageSnippet td,
.prImageSnippet article,
.prImageSnippet aside,
.prImageSnippet canvas,
.prImageSnippet details,
.prImageSnippet embed,
.prImageSnippet figure,
.prImageSnippet figcaption,
.prImageSnippet footer,
.prImageSnippet header,
.prImageSnippet hgroup,
.prImageSnippet menu,
.prImageSnippet nav,
.prImageSnippet output,
.prImageSnippet ruby,
.prImageSnippet section,
.prImageSnippet summary,
.prImageSnippet time,
.prImageSnippet mark,
.prImageSnippet audio,
.prImageSnippet video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%; */
  /* font: inherit; */
  vertical-align: baseline;
  box-sizing: content-box;
}

.pr-base-modal article,
.pr-base-modal aside,
.pr-base-modal details,
.pr-base-modal figcaption,
.pr-base-modal figure,
.pr-base-modal footer,
.pr-base-modal header,
.pr-base-modal hgroup,
.pr-base-modal menu,
.pr-base-modal nav,
.pr-base-modal section,
.pr-review-engine article,
.pr-review-engine aside,
.pr-review-engine details,
.pr-review-engine figcaption,
.pr-review-engine figure,
.pr-review-engine footer,
.pr-review-engine header,
.pr-review-engine hgroup,
.pr-review-engine menu,
.pr-review-engine nav,
.pr-review-engine section,
.pr-snippet article,
.pr-snippet aside,
.pr-snippet details,
.pr-snippet figcaption,
.pr-snippet figure,
.pr-snippet footer,
.pr-snippet header,
.pr-snippet hgroup,
.pr-snippet menu,
.pr-snippet nav,
.pr-snippet section,
.pr-pa-snippet article,
.pr-pa-snippet aside,
.pr-pa-snippet details,
.pr-pa-snippet figcaption,
.pr-pa-snippet figure,
.pr-pa-snippet footer,
.pr-pa-snippet header,
.pr-pa-snippet hgroup,
.pr-pa-snippet menu,
.pr-pa-snippet nav,
.pr-pa-snippet section,
.prImageSnippet article,
.prImageSnippet aside,
.prImageSnippet details,
.prImageSnippet figcaption,
.prImageSnippet figure,
.prImageSnippet footer,
.prImageSnippet header,
.prImageSnippet hgroup,
.prImageSnippet menu,
.prImageSnippet nav,
.prImageSnippet section {
  display: block;
}

.pr-base-modal body,
.pr-review-engine body,
.pr-snippet body,
.pr-pa-snippet body,
.prImageSnippet body {
  line-height: 1;
}

.pr-base-modal ol,
.pr-base-modal ul,
.pr-review-engine ol,
.pr-review-engine ul,
.pr-snippet ol,
.pr-snippet ul,
.pr-pa-snippet ol,
.pr-pa-snippet ul,
.prImageSnippet ol,
.prImageSnippet ul {
  list-style: none;
}

.pr-base-modal blockquote,
.pr-base-modal q,
.pr-review-engine blockquote,
.pr-review-engine q,
.pr-snippet blockquote,
.pr-snippet q,
.pr-pa-snippet blockquote,
.pr-pa-snippet q,
.prImageSnippet blockquote,
.prImageSnippet q {
  quotes: none;
}

.pr-base-modal blockquote:before, .pr-base-modal blockquote:after,
.pr-review-engine blockquote:before,
.pr-review-engine blockquote:after,
.pr-snippet blockquote:before,
.pr-snippet blockquote:after,
.pr-pa-snippet blockquote:before,
.pr-pa-snippet blockquote:after,
.prImageSnippet blockquote:before,
.prImageSnippet blockquote:after {
  content: '';
  content: none;
}

.pr-base-modal q:before, .pr-base-modal q:after,
.pr-review-engine q:before,
.pr-review-engine q:after,
.pr-snippet q:before,
.pr-snippet q:after,
.pr-pa-snippet q:before,
.pr-pa-snippet q:after,
.prImageSnippet q:before,
.prImageSnippet q:after {
  content: '';
  content: none;
}

.pr-base-modal table,
.pr-review-engine table,
.pr-snippet table,
.pr-pa-snippet table,
.prImageSnippet table {
  border-collapse: collapse;
  border-spacing: 0;
}

.pr-base-modal a,
.pr-review-engine a,
.pr-snippet a,
.pr-pa-snippet a,
.prImageSnippet a {
  color: #4a99ca;
  text-decoration: none;
}

.pr-base-modal a:hover,
.pr-review-engine a:hover,
.pr-snippet a:hover,
.pr-pa-snippet a:hover,
.prImageSnippet a:hover {
  color: #276891;
}

.pr-base-modal p,
.pr-base-modal ul,
.pr-base-modal li,
.pr-review-engine p,
.pr-review-engine ul,
.pr-review-engine li,
.pr-snippet p,
.pr-snippet ul,
.pr-snippet li,
.pr-pa-snippet p,
.pr-pa-snippet ul,
.pr-pa-snippet li,
.prImageSnippet p,
.prImageSnippet ul,
.prImageSnippet li {
  margin: 0;
}

.pr-base-modal ul,
.pr-review-engine ul,
.pr-snippet ul,
.pr-pa-snippet ul,
.prImageSnippet ul {
  list-style: none;
  padding-left: 0;
}

.pr-base-modal table,
.pr-base-modal tr,
.pr-base-modal td,
.pr-review-engine table,
.pr-review-engine tr,
.pr-review-engine td,
.pr-snippet table,
.pr-snippet tr,
.pr-snippet td,
.pr-pa-snippet table,
.pr-pa-snippet tr,
.pr-pa-snippet td,
.prImageSnippet table,
.prImageSnippet tr,
.prImageSnippet td {
  border-spacing: 0;
}

.pr-review-engine .pr-stars,
.pr-review-engine .pr-stars-small,
.pr-base-modal .pr-stars,
.pr-base-modal .pr-stars-small,
.pr-snippet .pr-stars,
.pr-snippet .pr-stars-small {
  display: inline-block;
  margin-right: 10px;
  background-repeat: no-repeat;
}

.pr-review-engine .pr-stars,
.pr-base-modal .pr-stars,
.pr-snippet .pr-stars {
  height: 20px;
  width: 112px;
}

.pr-review-engine .pr-stars-small,
.pr-base-modal .pr-stars-small,
.pr-snippet .pr-stars-small {
  height: 16px;
  width: 83px;
}

.pr-base-modal .pr-closed,
.pr-review-engine .pr-closed {
  display: none;
}

.pr-base-modal .pr-hidden,
.pr-review-engine .pr-hidden {
  visibility: hidden;
}

.pr-base-modal .pr-snapshot-change-locale-wrapper,
.pr-review-engine .pr-snapshot-change-locale-wrapper {
  margin-bottom: 10px;
}

.pr-base-modal .pr-rating,
.pr-base-modal .pr-review-author-date,
.pr-review-engine .pr-rating,
.pr-review-engine .pr-review-author-date {
  border: 1px solid #cccccc;
  border-radius: 2px;
  display: inline-block;
  background: #ffffff;
  padding: 1px 6px;
  margin-bottom: 10px;
  height: 20px;
}

.pr-base-modal .pr-review-author-name span,
.pr-base-modal .pr-review-author-location span,
.pr-base-modal .pr-review-author-affinities span,
.pr-review-engine .pr-review-author-name span,
.pr-review-engine .pr-review-author-location span,
.pr-review-engine .pr-review-author-affinities span {
  line-height: 1.5;
  font-weight: normal;
}

.pr-base-modal .pr-review-author-follow-link-container,
.pr-review-engine .pr-review-author-follow-link-container {
  margin-top: 20px;
}

.pr-base-modal .pr-review-author,
.pr-review-engine .pr-review-author {
  margin-bottom: 20px;
}

.pr-base-modal .pr-review-wrap,
.pr-review-engine .pr-review-wrap {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}

.pr-base-modal .pr-review-wrap .pr-attribute-key,
.pr-review-engine .pr-review-wrap .pr-attribute-key {
  margin-bottom: 5px;
}

.pr-base-modal .pr-review-wrap .pr-attribute-key p,
.pr-review-engine .pr-review-wrap .pr-attribute-key p {
  background-color: #f6f6f6;
  padding: 5px;
  font-size: 14px;
  border-radius: 2px;
}

.pr-base-modal .pr-review-wrap .pr-attribute-value-list,
.pr-review-engine .pr-review-wrap .pr-attribute-value-list {
  margin-left: 5px;
}

.pr-base-modal .pr-review-wrap .pr-attribute-value-list li,
.pr-review-engine .pr-review-wrap .pr-attribute-value-list li {
  list-style: none !important;
  font-size: 14px;
}

.pr-base-modal .pr-review-wrap .pr-review-footer,
.pr-review-engine .pr-review-wrap .pr-review-footer {
  clear: both;
}

.pr-base-modal .pr-review-wrap + .pr-pagination-bottom,
.pr-review-engine .pr-review-wrap + .pr-pagination-bottom {
  border-top: 0;
  padding-top: 0;
}

.pr-base-modal .pr-attribute-group,
.pr-review-engine .pr-attribute-group {
  margin-bottom: 20px;
}

.pr-base-modal .pr-review-points,
.pr-review-engine .pr-review-points {
  overflow: hidden;
  clear: both;
  margin-bottom: 20px;
}

.pr-base-modal .pr-other-attributes-histogram .pr-other-attribute-label,
.pr-review-engine .pr-other-attributes-histogram .pr-other-attribute-label {
  display: block;
}

.pr-base-modal .pr-other-attribute-value-histogram-label,
.pr-review-engine .pr-other-attribute-value-histogram-label {
  width: 140px;
  line-height: 1.4;
}

.pr-base-modal .pr-other-attribute-value-histogram-wrapper,
.pr-review-engine .pr-other-attribute-value-histogram-wrapper {
  width: 100%;
}

.pr-base-modal .pr-other-attribute-value-histogram-bar,
.pr-base-modal .pr-other-attribute-value-histogram-stats,
.pr-review-engine .pr-other-attribute-value-histogram-bar,
.pr-review-engine .pr-other-attribute-value-histogram-stats {
  padding-left: 20px;
}

.pr-base-modal .pr-other-attribute-value-histogram-stats,
.pr-review-engine .pr-other-attribute-value-histogram-stats {
  width: 1%;
  padding-right: 8%;
  white-space: nowrap;
}

.pr-base-modal .pr-other-attribute-value-histogram-stats .pr-snippet-review-count,
.pr-review-engine .pr-other-attribute-value-histogram-stats .pr-snippet-review-count {
  display: none;
}

.pr-base-modal .pr-other-attribute-value-histogram-stats p,
.pr-review-engine .pr-other-attribute-value-histogram-stats p {
  display: inline;
}

.pr-base-modal .pr-comments-header,
.pr-review-engine .pr-comments-header {
  display: none;
}

.pr-base-modal .pr-comments,
.pr-review-engine .pr-comments {
  clear: both;
  margin-bottom: 20px;
}

.pr-base-modal .pr-pic-content,
.pr-review-engine .pr-pic-content {
  border: 1px solid #cccccc;
  border-radius: 2px;
  margin-bottom: 5px;
}

.pr-base-modal .pr-customer-media,
.pr-review-engine .pr-customer-media {
  overflow: hidden;
}

.pr-base-modal .pr-customer-media-user,
.pr-review-engine .pr-customer-media-user {
  margin-bottom: 5px;
}

.pr-base-modal .pr-caption,
.pr-review-engine .pr-caption {
  margin-bottom: 10px;
}

.pr-base-modal .pr-pic-content,
.pr-review-engine .pr-pic-content {
  display: inline-block;
  padding: 2px;
}

.pr-base-modal .pr-pic-tags,
.pr-review-engine .pr-pic-tags {
  display: none;
}

.pr-base-modal .pr-customer-media-wrapper,
.pr-review-engine .pr-customer-media-wrapper {
  margin-bottom: 15px;
}

.pr-base-modal .pr-customer-media-wrapper .wrap2,
.pr-review-engine .pr-customer-media-wrapper .wrap2 {
  line-height: 0;
}

.pr-base-modal .pr-review-bottom-line,
.pr-review-engine .pr-review-bottom-line {
  display: block;
  margin-bottom: 5px;
}

.pr-base-modal .pr-other-attribute-tag-group,
.pr-review-engine .pr-other-attribute-tag-group {
  margin-bottom: 20px;
}

.pr-base-modal .pr-review-bottom-line-wrapper,
.pr-review-engine .pr-review-bottom-line-wrapper {
  margin-bottom: 20px;
}

.pr-base-modal .pr-review-helpful-text,
.pr-review-engine .pr-review-helpful-text {
  display: inline-block;
  font-weight: bold;
  color: #303533;
}

.pr-base-modal .pr-review-helpful-text a,
.pr-review-engine .pr-review-helpful-text a {
  font-weight: normal;
}

.pr-base-modal .pr-review-report-issue,
.pr-review-engine .pr-review-report-issue {
  display: block;
}

.pr-base-modal .pr-review-report-issue-hyphen,
.pr-review-engine .pr-review-report-issue-hyphen {
  display: none;
}

.pr-base-modal .pr-attribute-label-pros,
.pr-base-modal .pr-attribute-label-cons,
.pr-base-modal .pr-attribute-label-bestuses,
.pr-base-modal .pr-attribute-label-rating,
.pr-review-engine .pr-attribute-label-pros,
.pr-review-engine .pr-attribute-label-cons,
.pr-review-engine .pr-attribute-label-bestuses,
.pr-review-engine .pr-attribute-label-rating {
  margin: 0;
}

.pr-base-modal .pr-attribute-value-list,
.pr-review-engine .pr-attribute-value-list {
  list-style: none;
  padding-left: 0;
}

.pr-base-modal .pr-attribute-value-list li,
.pr-review-engine .pr-attribute-value-list li {
  list-style: none !important;
  margin-bottom: 5px;
}

.pr-base-modal .pr-attribute-value-list li:last-child,
.pr-review-engine .pr-attribute-value-list li:last-child {
  margin-bottom: 0;
}

.pr-base-modal .pr-other-attribute-label,
.pr-review-engine .pr-other-attribute-label {
  margin-bottom: 5px;
}

.pr-base-modal .pr-review-main-wrapper,
.pr-review-engine .pr-review-main-wrapper {
  margin-bottom: 20px;
}

.pr-base-modal .pr-fb-reviewComment,
.pr-base-modal .pr-fb-review-comment,
.pr-review-engine .pr-fb-reviewComment,
.pr-review-engine .pr-fb-review-comment {
  border-top: 1px solid #cccccc;
  margin-bottom: 20px;
  padding-top: 20px;
}

.pr-base-modal .pr-fb-reviewComment:before,
.pr-base-modal .pr-fb-review-comment:before,
.pr-review-engine .pr-fb-reviewComment:before,
.pr-review-engine .pr-fb-review-comment:before {
  background: url("images/reviews-comments-facebook-icon_01.png") no-repeat;
  background-size: 16px 16px;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  content: '';
  display: inline-block;
  vertical-align: middle;
}

.pr-base-modal .pr-fb-reviewComment span,
.pr-base-modal .pr-fb-review-comment span,
.pr-review-engine .pr-fb-reviewComment span,
.pr-review-engine .pr-fb-review-comment span {
  margin-right: 5px;
  vertical-align: middle;
}

.pr-base-modal .pr-contents-wrapper .pr-review-wrap:last-of-type,
.pr-review-engine .pr-contents-wrapper .pr-review-wrap:last-of-type {
  border-bottom: 1px solid #cccccc;
}

.pr-base-modal .pr-profile-fb-help-icon,
.pr-review-engine .pr-profile-fb-help-icon {
  display: none;
}

.pr-base-modal .pr-review-rating-wrapper,
.pr-review-engine .pr-review-rating-wrapper {
  margin-bottom: 10px;
}

.pr-base-modal .pr-review-attribution-img,
.pr-review-engine .pr-review-attribution-img {
  margin-bottom: 20px;
}

.pr-base-modal .pr-review-attribute-imgLabel,
.pr-review-engine .pr-review-attribute-imgLabel {
  font-size: 12px;
}

.pr-base-modal .syndication-logo-lnk img,
.pr-review-engine .syndication-logo-lnk img {
  width: 70px;
}

.pr-base-modal .pr-review-author-badge-wrapper > *,
.pr-review-engine .pr-review-author-badge-wrapper > * {
  margin-bottom: 10px;
}

.pr-base-modal .pr-review-author-badge-wrapper > * *:last-child,
.pr-review-engine .pr-review-author-badge-wrapper > * *:last-child {
  margin-bottom: 0;
}

.pr-review-engine {
  padding: 10px;
}

.pr-review-engine .pr-snapshot-fb-get-advice-container,
.pr-review-engine .pr-snapshot-fb-follow-product-category-button-wrapper {
  background-color: #dbe6f4;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;
  width: 240px;
  box-sizing: border-box;
}

.pr-review-engine .pr-snapshot-fb-get-advice-container:before,
.pr-review-engine .pr-snapshot-fb-follow-product-category-button-wrapper:before {
  display: block;
  content: '';
  position: absolute;
  right: 10px;
}

.pr-review-engine .pr-snapshot-fb-get-advice-container:before {
  background: url("images/reviews-comments-facebook-icon_01.png") no-repeat;
  background-size: 16px 16px;
  margin-top: 10px;
  width: 16px;
  height: 16px;
}

.pr-review-engine .pr-snapshot-fb-follow-product-category-button-wrapper:before {
  background: url("images/reviews-subscribe-mail-icon_01.png") no-repeat;
  background-size: 22px 16px;
  margin-top: 10px;
  width: 22px;
  height: 16px;
}

.pr-review-engine #pr-snapshot-histogram {
  display: inline-block;
  margin-left: 5px;
}

.pr-review-engine .pr-selected .pr-ratings-histogram-barValue {
  background: linear-gradient(#e99b3e, #cf7912);
}

.pr-review-engine .pr-selected .pr-ratings-histogram-bar {
  background: #ddd;
}

.pr-review-engine .pr-attribute-value-list-empty {
  font-size: 14px;
  text-align: center;
  color: #999999;
}

.pr-review-engine #pr-snapshot-histogram-container {
  border: 1px solid #cccccc;
  border-radius: 2px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 245px;
  margin-top: 10px;
  padding: 15px;
  background: #ffffff;
  z-index: 9999;
}

.pr-review-engine #pr-snapshot-histogram-container .pr-ratings-histogram-bar {
  width: 148px;
}

.pr-review-engine #pr-snapshot-histogram-header {
  margin-bottom: 5px;
}

.pr-review-engine .pr-histogram-5Stars,
.pr-review-engine .pr-histogram-4Stars,
.pr-review-engine .pr-histogram-3Stars,
.pr-review-engine .pr-histogram-2Stars,
.pr-review-engine .pr-histogram-1Stars {
  display: table;
  width: 100%;
  margin-bottom: 5px;
}

.pr-review-engine .pr-histogram-5Stars:last-child,
.pr-review-engine .pr-histogram-4Stars:last-child,
.pr-review-engine .pr-histogram-3Stars:last-child,
.pr-review-engine .pr-histogram-2Stars:last-child,
.pr-review-engine .pr-histogram-1Stars:last-child {
  margin-bottom: 0;
}

.pr-review-engine .pr-histogram-5Stars > *,
.pr-review-engine .pr-histogram-4Stars > *,
.pr-review-engine .pr-histogram-3Stars > *,
.pr-review-engine .pr-histogram-2Stars > *,
.pr-review-engine .pr-histogram-1Stars > * {
  display: table-cell;
}

.pr-review-engine .pr-histogram-label,
.pr-review-engine .pr-histogram-count {
  font-size: 12px;
}

.pr-review-engine .pr-histogram-label {
  padding-right: 15px;
  white-space: nowrap;
  width: 40px;
}

.pr-review-engine .pr-histogram-count {
  padding-left: 10px;
  width: 30px;
}

.pr-review-engine .pr-attribute-collapse {
  display: block;
}

.pr-review-engine .pr-review-fb-social-loyalty {
  margin-bottom: 5px;
}

.pr-review-engine .pr-review-social-bar {
  margin-bottom: 20px;
}

.pr-review-engine .pr-snapshot-title {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.pr-review-engine .pr-snapshot-cobrand {
  display: block;
}

.pr-review-engine .pr-snapshot-head {
  background: none;
}

.pr-review-engine .pr-snapshot-head-wrapper {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.pr-review-engine .pr-snapshot-product-name {
  display: none;
}

.pr-review-engine .pr-snapshot-rating {
  position: relative;
}

.pr-review-engine .pr-snapshot-rating .pr-rating {
  margin-right: 5px;
}

.pr-review-engine .pr-snapshot-average-based-on-text {
  margin-bottom: 10px;
}

.pr-review-engine .pr-snapshot-consensus {
  margin-bottom: 20px;
}

.pr-review-engine .pr-snapshot-consensus-value {
  color: #ffffff;
  background: #84bf41;
  display: inline-block;
  border-radius: 2px;
  padding: 4px 8px;
  margin-bottom: 5px;
}

.pr-review-engine .pr-snapshot-consensus-text {
  display: inline-block;
}

.pr-review-engine .pr-snapshot-footer {
  clear: both;
  margin-bottom: 20px;
}

.pr-review-engine .pr-snapshot-write-review-social-loyalty {
  margin-top: 5px;
}

.pr-review-engine .pr-snapshot .pr-attribute-label-pros,
.pr-review-engine .pr-snapshot .pr-attribute-label-cons,
.pr-review-engine .pr-snapshot .pr-attribute-label-bestuses,
.pr-review-engine .pr-snapshot .pr-attribute-label-rating {
  padding: 15px;
}

.pr-review-engine .pr-snapshot .pr-attribute-group {
  border: 1px solid #cccccc;
  border-radius: 2px;
}

.pr-review-engine .pr-snapshot .pr-attribute-key {
  background: #f6f6f6;
}

.pr-review-engine .pr-snapshot .pr-attribute-key,
.pr-review-engine .pr-snapshot .pr-attribute-key * {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.pr-review-engine .pr-last {
  margin-bottom: 0;
}

.pr-review-engine .pr-snapshot-attribute-value-list {
  padding: 15px !important;
}

.pr-review-engine .pr-other-attributes {
  clear: both;
}

.pr-review-engine .pr-other-attributes-list,
.pr-review-engine .pr-other-attributes-list ul {
  list-style: none;
  padding-left: 0;
}

.pr-review-engine .pr-other-attributes-list .pr-other-attributes-group,
.pr-review-engine .pr-other-attributes-list ul .pr-other-attributes-group {
  overflow: hidden;
  margin-bottom: 20px;
}

.pr-review-engine .pr-other-attributes-list .pr-other-attributes-group:last-child,
.pr-review-engine .pr-other-attributes-list ul .pr-other-attributes-group:last-child {
  margin-bottom: 0;
}

.pr-review-engine .pr-write-review-link {
  font-size: 18px;
  font-weight: bold;
}

.pr-review-engine .pr-review-faceoff {
  border: 1px solid #cccccc;
  border-radius: 2px;
  margin-bottom: 20px;
}

.pr-review-engine .pr-review-faceoff .pr-stars {
  margin-bottom: 10px;
}

.pr-review-engine .pr-review-faceoff-review-full {
  display: none;
}

.pr-review-engine .pr-review-faceoff-label {
  margin-bottom: 10px;
}

.pr-review-engine .pr-review-faceoff-title {
  margin-bottom: 5px;
}

.pr-review-engine .pr-review-faceoff-review-positive,
.pr-review-engine .pr-review-faceoff-review-negative {
  padding: 20px;
}

.pr-review-engine .pr-review-faceoff-img {
  border-bottom: 1px solid #cccccc;
  float: left;
  height: 1px;
  top: 50%;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
}

.pr-review-engine .pr-review-faceoff-img-txt {
  border: 1px solid #cccccc;
  border-radius: 2px;
  display: inline-block;
  background: #ffffff;
  padding: 8px 12px;
  margin-top: -17px;
}

.pr-review-engine .pr-review-faceoff-readcomplete {
  display: block;
}

.pr-review-engine .pr-review-faceoff-readcomplete {
  margin-top: 10px;
}

.pr-review-engine .pr-review-content-header {
  display: none;
}

.pr-review-engine .pr-ratings-histogram-content {
  padding: 0px !important;
}

.pr-review-engine .pr-review-sort {
  background: #f6f6f6;
  text-align: center;
  padding: 15px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.pr-review-engine .pr-review-count {
  margin-bottom: 10px;
}

.pr-review-engine .pr-review-sort-by {
  display: inline-block;
  margin-right: 5px;
}

.pr-review-engine .pr-review-sort-box select {
  border: 1px solid #cccccc;
  border-radius: 2px;
  background: #ffffff;
}

.pr-review-engine .pr-pagination-top {
  visibility: hidden;
  height: 0px;
  width: 0px;
}

.pr-review-engine .pr-page-nav-wrapper {
  margin-top: 10px;
  font-size: 16px;
}

.pr-review-engine .pr-page-count,
.pr-review-engine .pr-nav-back-to-top {
  display: inline-block;
}

.pr-review-engine .pr-page-count {
  margin-right: 10px;
}

.pr-review-engine .pr-review-most-helpful p {
  margin-bottom: 10px;
}

.pr-review-engine .pr-review-most-helpful-bottom {
  display: none;
}

.pr-review-engine .pr-review-rating-wrapper,
.pr-review-engine .pr-review-author-info-wrapper {
  margin-bottom: 20px;
}

.pr-review-engine .pr-pagination-bottom {
  text-align: center;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  padding-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.pr-review-engine .pr-snapshot-average-based-on-text {
  display: inline-block;
}

.pr-review-engine .pr-disclosure-message {
  padding: 5px;
  background-color: #eee;
  font-style: italic;
  margin: 15px 0;
}

.pr-review-engine .pr-disclosure-message .pr-disclosure-text {
  color: #767676;
}

.pr-review-engine .pr-base-modal,
.pr-base-modal {
  position: fixed;
  box-sizing: border-box;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  border: 10px solid rgba(118, 118, 118, 0.3);
  background-clip: padding-box;
  border-radius: 2px;
  background: #ffffff;
  margin-left: -150px;
  top: 5% !important;
  height: auto !important;
  max-height: 90%;
  overflow: auto;
  z-index: 9999;
  width: 300px !important;
}

.pr-review-engine .pr-base-modal.pr-hidden,
.pr-review-engine .pr-base-modal .pr-hidden,
.pr-base-modal.pr-hidden,
.pr-base-modal .pr-hidden {
  display: none;
}

.pr-review-engine .pr-base-modal .pr-modal-container,
.pr-base-modal .pr-modal-container {
  position: static;
  box-sizing: border-box;
  width: 100% !important;
  height: 100% !important;
  height: 340px;
  overflow: auto;
  padding: 15px;
}

.pr-review-engine .pr-base-modal .pr-modal-shadow,
.pr-base-modal .pr-modal-shadow {
  display: none;
}

.pr-review-engine .pr-base-modal .pr-profile-author-information,
.pr-base-modal .pr-profile-author-information {
  margin-bottom: 20px;
}

.pr-review-engine .pr-base-modal .pr-ratings-histogram-content > li,
.pr-base-modal .pr-ratings-histogram-content > li {
  margin-bottom: 5px;
}

.pr-review-engine .pr-base-modal .pr-histogram-label,
.pr-review-engine .pr-base-modal .pr-histogram-count,
.pr-base-modal .pr-histogram-label,
.pr-base-modal .pr-histogram-count {
  display: inline-block;
}

.pr-review-engine .pr-base-modal .pr-ratings-histogram-bar,
.pr-base-modal .pr-ratings-histogram-bar {
  width: 150px;
}

.pr-review-engine .pr-base-modal .pr-profile-histogram-header,
.pr-base-modal .pr-profile-histogram-header {
  margin-bottom: 10px;
}

.pr-review-engine .pr-base-modal .pr-profile-histogram-container,
.pr-base-modal .pr-profile-histogram-container {
  margin-bottom: 20px;
}

.pr-review-engine .pr-base-modal .pr-histogram-label,
.pr-review-engine .pr-base-modal .pr-histogram-count,
.pr-base-modal .pr-histogram-label,
.pr-base-modal .pr-histogram-count {
  font-size: 12px;
}

.pr-review-engine .pr-base-modal .pr-histogram-label,
.pr-base-modal .pr-histogram-label {
  padding-right: 15px;
  white-space: nowrap;
}

.pr-review-engine .pr-base-modal .pr-histogram-count,
.pr-base-modal .pr-histogram-count {
  padding-left: 15px;
  width: 50px;
}

.pr-review-engine .pr-base-modal .pr-review-author-affinities span,
.pr-base-modal .pr-review-author-affinities span {
  font-weight: normal;
}

.pr-review-engine .pr-base-modal .pr-review-author-affinity-wrapper,
.pr-review-engine .pr-base-modal .pr-profile-fb-manage-settings,
.pr-base-modal .pr-review-author-affinity-wrapper,
.pr-base-modal .pr-profile-fb-manage-settings {
  margin-bottom: 15px;
}

.pr-review-engine .pr-base-modal .pr-profile-reviews-header,
.pr-base-modal .pr-profile-reviews-header {
  margin-bottom: 20px;
}

.pr-review-engine .pr-base-modal .pr-profile-review-product-info *:last-child,
.pr-base-modal .pr-profile-review-product-info *:last-child {
  display: block;
  margin-bottom: 20px;
}

.pr-review-engine .pr-base-modal .pr-close,
.pr-base-modal .pr-close {
  background: url("images/modal-close.png") no-repeat scroll left top transparent;
  display: block;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 16px;
  z-index: 10000;
}

.pr-review-engine .pr-base-modal .pr-close > span,
.pr-base-modal .pr-close > span {
  display: none;
}

.pr-review-engine .pr-base-modal .pr-fb-follow-modal-header,
.pr-base-modal .pr-fb-follow-modal-header {
  margin-bottom: 10px;
}

.pr-review-engine .pr-base-modal .pr-fb-follow-email-input,
.pr-base-modal .pr-fb-follow-email-input {
  border-radius: 2px;
  font-size: 16px;
  padding: 10px;
  margin-right: 15px;
}

.pr-review-engine .pr-base-modal .pr-fb-follow-submit-btn,
.pr-base-modal .pr-fb-follow-submit-btn {
  border: 1px solid #cccccc;
  border-radius: 2px;
  background: #f6f6f6;
  color: #4a99ca;
  font-size: 18px;
  padding: 10px;
}

.pr-review-engine .pr-base-modal .pr-fb-follow-info-wrapper > p,
.pr-base-modal .pr-fb-follow-info-wrapper > p {
  margin-top: 15px;
  margin-bottom: 15px;
}

.pr-review-engine .pr-base-modal .pr-fb-follow-content-wrapper > .pr-fb-follow-info > .pr-emphasis,
.pr-base-modal .pr-fb-follow-content-wrapper > .pr-fb-follow-info > .pr-emphasis {
  display: none;
}

.pr-review-engine .pr-base-modal .pr-review-faceoff-review,
.pr-base-modal .pr-review-faceoff-review {
  display: none;
}

.pr-review-engine .pr-base-modal .pr-review-faceoff-review-full,
.pr-base-modal .pr-review-faceoff-review-full {
  display: block;
}

.pr-review-engine .pr-base-modal .pr-review-faceoff-title,
.pr-base-modal .pr-review-faceoff-title {
  margin-top: 10px;
}

#pr-profile {
  box-sizing: border-box !important;
  position: fixed !important;
  border: 10px solid rgba(118, 118, 118, 0.3) !important;
  border-radius: 2px !important;
  background: #ffffff !important;
  height: auto !important;
  width: 700px !important;
  margin-left: -350px !important;
  top: 50% !important;
  left: 50% !important;
  bottom: auto !important;
  margin-top: -250px !important;
}

#pr-profile .pr-modal-container {
  position: static !important;
}

#pr-profile .pr-modal-shadow {
  display: none !important;
}

#pr-profile .pr-modal-loading {
  width: 100% !important;
}

#pr-profile .pr-modal-container {
  width: auto !important;
  height: 470px;
}

@media (max-width: 751px) {
  #pr-profile {
    width: 300px !important;
    margin-left: -150px !important;
  }
  #pr-profile .pr-user {
    height: 140px;
  }
  #pr-profile .pr-tab-content {
    height: 249px;
  }
  #pr-profile .pr-histogram-container {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-bottom: 10px;
  }
  #pr-profile ul.pr-stats {
    width: 100%;
  }
  #pr-profile li.pr-review .pr-thumb,
  #pr-profile li.pr-answer .pr-thumb {
    width: auto;
    float: none;
  }
  #pr-profile li.pr-review .pr-review-main,
  #pr-profile li.pr-review .pr-answer-main,
  #pr-profile li.pr-answer .pr-review-main,
  #pr-profile li.pr-answer .pr-answer-main {
    width: auto;
    float: none;
    padding-right: 0;
  }
  #pr-profile li.pr-review .pr-tags {
    float: none;
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  #pr-profile li.pr-review h3 {
    margin-bottom: 0;
  }
}

.pr-snippet {
  display: inline-block;
}

.pr-snippet .pr-snippet-rating-decimal {
  border: 1px solid #cccccc;
  border-radius: 2px;
  vertical-align: middle;
  font-weight: bold;
  display: inline-block;
  background: #ffffff;
  padding: 1px 6px;
  height: 18px;
  margin-right: 5px;
}

.pr-snippet .pr-snippet-review-count {
  margin-left: 5px;
  display: inline-block;
}

.pr-snippet .pr-stars {
  vertical-align: middle;
}

.pr-snippet .pr-snippet-stars {
  margin-bottom: 5px;
}

.pr-snippet .pr-snippet-stars,
.pr-snippet .pr-snippet-read-reviews,
.pr-snippet .pr-snippet-write-review {
  display: inline-block;
}

.pr-snippet .pr-snippet-read-reviews,
.pr-snippet .pr-snippet-write-review {
  margin-right: 15px;
}

.pr-snippet .pr-snippet-read-reviews span {
  font-weight: bold;
}

.pr-snippet .pr-snippet-read-write {
  display: block;
}

.pr-snippet .pr-snippet-social-bar {
  margin-top: 5px;
}

.pr-snippet .pr-snippet-social-bar .pr-google-plus-one {
  margin-bottom: 5px;
}

.pr-pa-snippet .pr-pa-snippet-title,
.pr-pa-snippet .pr-pa-snippet-text,
.pr-pa-snippet .pr-pa-snippet-questions,
.pr-pa-snippet .pr-pa-snippet-answers,
.pr-pa-snippet .pr-pa-snippet-read-questions,
.pr-pa-snippet .pr-pa-snippet-ask-question {
  display: inline-block;
}

.pr-pa-snippet .pr-pa-snippet-wrapper > .pr-pa-snippet-text {
  display: none;
}

.pr-pa-snippet .pr-pa-snippet-questions,
.pr-pa-snippet .pr-pa-snippet-answers {
  margin-right: 10px;
}

.pr-pa-snippet .pr-pa-snippet-ask-question {
  display: inline;
  font-size: 12px;
}

.pr-pa-snippet .pr-pa-snippet-title {
  color: #303533;
  font-weight: bold;
  margin-right: 5px;
}

.prImageSnippet .prImageSnippetImage {
  border: 1px solid #cccccc;
  float: left;
  height: 38px;
  margin-right: 1px;
  overflow: hidden;
  width: 38px;
}

.prImageSnippet .prImageSnippetImage img.prImageSnippetImageLandscape {
  height: 38px;
}

.prImageSnippet .prImageSnippetImage img {
  border: 0 none;
}

.pr-summary-snippet .pr-other-attributes-histogram .pr-other-attribute-label {
  display: block;
}

.pr-summary-snippet .pr-other-attribute-value-histogram-label {
  width: 140px;
  line-height: 1.4;
}

.pr-summary-snippet .pr-other-attribute-value-histogram-wrapper {
  width: 100%;
}

.pr-summary-snippet .pr-other-attribute-value-histogram-bar,
.pr-summary-snippet .pr-other-attribute-value-histogram-stats {
  padding-left: 20px;
}

.pr-summary-snippet .pr-other-attribute-value-histogram-stats {
  width: 1%;
  padding-right: 8%;
  white-space: nowrap;
}

.pr-summary-snippet .pr-other-attribute-value-histogram-stats .pr-snippet-review-count {
  display: none;
}

.pr-summary-snippet .pr-other-attribute-value-histogram-stats p {
  display: inline;
}

.prPaContainer,
.prPaFooter {
  width: 38.5em;
  /*550px eq*/
  font-family: arial;
  font-size: 85%;
  color: #767676;
  background: #f3f3f3;
}

.prPaContainer {
  padding: 1em;
}

.prPaContainer p {
  margin: 0;
  padding: 0;
  color: #000;
}

.prPaQ,
.prPaA {
  font-size: 114%;
}

.prPaHeader .prPaQuestionPrompt {
  padding: 0.6em 0;
  font-size: 85%;
  *font-size: 80%;
  color: #767676;
}

.prPaHeader .prPaQuestionPrompt span {
  font-weight: bold;
  color: #000000;
}

.prPaHeader .prPaQuestionPromptIntro {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.prPaHeader .prPaQuestionPromptIntro span {
  font-size: 85%;
  *font-size: 80%;
  color: #767676;
  font-weight: normal;
}

.prPaQuestionPromptIntroSLPoints {
  margin-left: 0.5em;
}

.prPaFooter {
  text-align: right;
  font-family: arial;
  font-size: 85%;
  color: #000;
  background: #f3f3f3;
  border-top: 1px solid #d7d7d7;
  padding: 0.7em 1em;
  /* L/R padding must match prPaContainer */
}

body:last-child:not(:root:root) .prPaFooter {
  /*for safari */
  padding: 0.7em 0;
}

.prPaFooter .prPaQuestionPromptIntro {
  font-weight: bold;
  margin: 0;
  padding: 0.4em 0 0 0;
}

.prPaQuestionSubhead {
  padding: 5px 0;
}

.prPaAskQuestionLnk {
  background: transparent url(images/btn_ask_question_r.png) right 0 no-repeat;
  color: #fff;
  display: block;
  float: right;
  font-weight: bold;
  height: 24px;
  margin-right: 6px;
  padding-right: 8px;
  text-decoration: none;
}

.prPaAskQuestionLnk span {
  background: transparent url(images/btn_ask_question_l.png) 0 0 no-repeat;
  display: block;
  line-height: 14px;
  padding: 5px 0 5px 18px;
  color: #fff;
  text-decoration: none;
}

.prPaQaContainer {
  margin-left: -40px;
  *margin-left: 0px;
}

.prPaContainer ul {
  list-style: none;
}

.prPaAnswerCont ul li {
  border: none;
}

.prPaContainer li {
  margin-left: 0;
  border: 1px solid #d7d7d7;
  margin-bottom: 0.5em;
  padding: 0.5em 0.5em 0 0.5em;
  background: #fff;
}

.prPaContainer .prPaAnswerList li {
  padding: 0;
}

.prPaCounts {
  padding-top: 1em;
  border-top: 1px solid #d7d7d7;
}

.prPaPagination {
  line-height: 1;
  color: #444;
  font-size: 0.95em;
  position: relative;
  margin: 0.9em 0;
}

.prPaPagination .prPaPageNav {
  position: absolute;
  right: 0;
}

.prPaQaVariantName {
  margin-bottom: 0.3em;
}

.prPaByLine {
  /*float: left;*/
  margin: 0.7em 0 0 0;
  font-size: 85%;
}

.prPaAddBorder {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 0.7em;
}

.prPaLastAnswer {
  padding-bottom: 0.5em;
}

.prPaDate {
  color: darkgray;
  display: inline;
}

.prPaQuestion {
  background-color: #eeeeff;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0.5em;
}

.prPaAnswerList {
  margin-left: -40px;
  *margin-left: 0px;
}

.prPaAnsweredIn {
  margin: 0;
  padding: 0;
  display: inline;
  font-size: 85%;
}

p.prPaTotalsLabel,
p.prPaAnswerLnk {
  margin: 1em 0 0 0;
  padding: 0 0 0.5em 0;
}

p.prPaAnswerLnk {
  font-size: 92%;
  float: right;
  color: #767676;
}

p.prPaAnswerLnk a {
  font-weight: bold;
  color: #fc961a;
}

p.prPaTotalsLabel {
  font-weight: bold;
  cursor: pointer;
  padding-left: 12px;
  margin-bottom: 0.5em;
}

.prPaSLFirstAnswer {
  font-weight: normal;
  font-size: 7pt;
  margin-left: 0.8em;
}

p.paPrQaPrefix {
  font-weight: bold;
  margin: 0 0.2em 0 0;
  padding: 0;
  display: inline;
}

.prPaBadgeCase {
  margin: 0 0 0.5em 0;
  height: 20px;
}

.prPaAnswerDialog p.prPaVerifiedMerchant {
  font-size: 77%;
  font-weight: bold;
  background: transparent url(images/badge_vm.gif) 0 0 no-repeat;
  padding-left: 25px;
  line-height: 20px;
}

.prPaAnswerDialog p.prPaVerifiedPurchaser {
  font-size: 77%;
  font-weight: bold;
  background: transparent url(images/badge_pa_vb.gif) 0 0 no-repeat;
  padding-left: 25px;
  line-height: 20px;
}

.prPaToggleClosed {
  background: transparent url(images/pa_carat_closed.gif) 0 0.4em no-repeat;
}

.prPaToggleOpen {
  background: transparent url(images/pa_carat_open.gif) 0 0.4em no-repeat;
}

.pr-review-engine-max-width-560px .pr-snapshot-consensus {
  display: table;
}

.pr-review-engine-max-width-560px .pr-snapshot-consensus-text,
.pr-review-engine-max-width-560px .pr-snapshot-consensus-value {
  display: table-cell;
  vertical-align: middle;
}

.pr-review-engine-max-width-560px .pr-snapshot-consensus-text {
  padding-left: 10px;
}

.pr-review-engine-max-width-560px .pr-snapshot-rating .pr-stars,
.pr-review-engine-max-width-560px .pr-snapshot-rating .pr-rating,
.pr-review-engine-max-width-560px .pr-snapshot-rating .pr-snapshot-average-based-on-text,
.pr-review-engine-max-width-560px .pr-snapshot-rating #pr-snapshot-histogram {
  float: left;
}

.pr-review-engine-max-width-560px .pr-snapshot-consensus {
  clear: left;
}

.pr-review-engine-max-width-560px .pr-snapshot-average-based-on-text {
  clear: left;
  font-size: 12px;
  margin-bottom: 15px;
}

.pr-review-engine-max-width-560px #pr-snapshot-histogram-container {
  left: 0;
}

.pr-review-engine-max-width-560px .pr-other-attributes-histogram .pr-snippet-review-count {
  display: none;
}

.pr-review-engine-max-width-560px .pr-attribute-group.pr-empty .pr-attribute-key {
  border-radius: 2px;
}

.pr-review-engine-max-width-560px .pr-attribute-group.pr-empty .pr-attribute-value {
  display: none;
}

.pr-review-engine-max-width-560px .pr-attribute-value-list-empty {
  line-height: 55px;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-other-attributes-list .pr-other-attribute-value,
.pr-review-engine-max-width-560px .pr-other-attributes-list .pr-other-attribute-value {
  margin-bottom: 15px;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-other-attributes-list .pr-other-attribute-value:last-child,
.pr-review-engine-max-width-560px .pr-other-attributes-list .pr-other-attribute-value:last-child {
  margin-bottom: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-other-attributes-subratings,
.pr-review-engine-max-width-560px .pr-other-attributes-subratings {
  margin-bottom: 15px;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-customer-media-wrapper,
.pr-review-engine-min-width-690px .pr-customer-media-wrapper {
  float: left;
  margin-right: 40px;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-review-wrap .pr-attribute-group,
.pr-review-engine-min-width-690px .pr-review-wrap .pr-attribute-group {
  box-sizing: border-box;
  float: left;
  width: 32%;
  margin-right: 2%;
  margin-bottom: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-review-wrap .pr-attribute-group.pr-last,
.pr-review-engine-min-width-690px .pr-review-wrap .pr-attribute-group.pr-last {
  margin-right: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-attribute-value-list-empty,
.pr-review-engine-min-width-690px .pr-attribute-value-list-empty {
  line-height: 155px;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-review-sort,
.pr-review-engine-min-width-690px .pr-review-sort {
  overflow: hidden;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-review-sort .pr-review-count,
.pr-review-engine-min-width-690px .pr-review-sort .pr-review-count {
  float: left;
  margin-bottom: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-review-sort .pr-review-sort-box,
.pr-review-engine-min-width-690px .pr-review-sort .pr-review-sort-box {
  float: right;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-group {
  box-sizing: border-box;
  float: left;
  width: 33%;
  margin-bottom: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-value {
  box-sizing: border-box;
  height: 160px;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-value-list {
  padding: 10px;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-value-list li {
  font-size: 12px;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-cons {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-cons .pr-attribute-key {
  border-radius: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-pros {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-bestuses {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-cons {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-cons .pr-attribute-key {
  border-top-left-radius: 0;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-label-pros,
.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-label-cons,
.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-label-bestuses,
.pr-review-engine-min-width-560px-max-width-690px .pr-snapshot .pr-attribute-label-rating {
  padding: 10px;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-review-author-name,
.pr-review-engine-min-width-560px-max-width-690px .pr-review-author-location,
.pr-review-engine-min-width-560px-max-width-690px .pr-review-author-affinities {
  display: inline;
}

.pr-review-engine-min-width-560px-max-width-690px .pr-review-author-name:after,
.pr-review-engine-min-width-560px-max-width-690px .pr-review-author-location:after,
.pr-review-engine-min-width-560px-max-width-690px .pr-review-author-affinities:after {
  content: ' ';
}

.pr-review-engine-min-width-560px-max-width-690px .pr-review-author-location {
  font-weight: normal;
}

.pr-review-engine-min-width-690px .pr-snapshot-title {
  margin-bottom: 0;
}

.pr-review-engine-min-width-690px .pr-snapshot-cobrand {
  display: inline-block;
}

.pr-review-engine-min-width-690px .pr-snapshot-rating {
  float: left;
}

.pr-review-engine-min-width-690px .pr-snapshot-consensus {
  float: right;
}

.pr-review-engine-min-width-690px .pr-snapshot-consensus-value {
  margin-right: 5px;
}

.pr-review-engine-min-width-690px .pr-attribute-group {
  box-sizing: border-box;
  float: left;
  width: 32%;
  margin-right: 2%;
  margin-bottom: 0;
}

.pr-review-engine-min-width-690px .pr-attribute-group.pr-last {
  margin-right: 0;
}

.pr-review-engine-min-width-690px .pr-snapshot .pr-attribute-value {
  box-sizing: border-box;
  height: 155px;
}

.pr-review-engine-min-width-690px .pr-snapshot .pr-last {
  margin-right: 0;
}

.pr-review-engine-min-width-690px .pr-last {
  margin-right: 0;
}

.pr-review-engine-min-width-690px .pr-review-faceoff {
  overflow: hidden;
}

.pr-review-engine-min-width-690px .pr-review-faceoff-review-positive,
.pr-review-engine-min-width-690px .pr-review-faceoff-review-negative {
  float: left;
  width: 44%;
  padding: 2%;
}

.pr-review-engine-min-width-690px .pr-review-faceoff-review-positive {
  padding-right: 4%;
}

.pr-review-engine-min-width-690px .pr-review-faceoff-review-negative {
  padding-left: 4%;
}

.pr-review-engine-min-width-690px .pr-review-faceoff-img {
  top: 0;
  margin: 0;
  border-right: 1px solid #ccc;
  float: left;
  height: 100%;
  left: 50%;
  position: absolute;
  text-align: center;
  width: 1px;
}

.pr-review-engine-min-width-690px .pr-review-faceoff-img-txt {
  top: 50%;
  position: absolute;
  margin-left: -23px;
}

.pr-review-engine-min-width-690px .pr-review-faceoff-review-wrapper {
  position: relative;
  overflow: hidden;
}

.pr-review-engine-min-width-690px .pr-review-bottom-line,
.pr-review-engine-min-width-690px .pr-other-attribute-label {
  float: left;
  clear: left;
  box-sizing: border-box;
  line-height: 1.5;
}

.pr-review-engine-min-width-690px .pr-other-attribute-value {
  float: left;
  width: 80%;
  line-height: 1.5;
}

.pr-review-engine-min-width-690px .pr-other-attribute-label,
.pr-review-engine-min-width-690px .pr-other-attribute-value,
.pr-review-engine-min-width-690px .pr-review-bottom-line {
  display: inline-block;
}

.pr-review-engine-min-width-690px .pr-other-attribute-label,
.pr-review-engine-min-width-690px .pr-review-bottom-line {
  padding-right: 10px;
}

.pr-review-engine-min-width-690px .pr-other-attribute-value-histogram-wrapper {
  width: 100%;
}

.pr-review-engine-min-width-690px .pr-other-attributes-subratings {
  overflow: hidden;
  clear: both;
}

.pr-review-engine-min-width-690px .pr-other-attributes-subratings .pr-other-attribute-label,
.pr-review-engine-min-width-690px .pr-other-attributes-subratings .pr-other-attribute-value {
  float: none;
  display: table-cell;
  vertical-align: middle;
}

.pr-review-engine-min-width-690px .pr-review-wrap {
  overflow: hidden;
}

.pr-review-engine-min-width-690px .pr-review-author {
  float: left;
  width: 20%;
  margin-right: 5%;
}

.pr-review-engine-min-width-690px .pr-review-rating-wrapper,
.pr-review-engine-min-width-690px .pr-review-main-wrapper,
.pr-review-engine-min-width-690px .pr-fb-reviewComment {
  float: right;
  width: 75%;
}

.pr-review-engine-min-width-690px .pr-fb-reviewComment {
  clear: both;
}

.pr-review-engine-min-width-690px .pr-review-author-date {
  float: right;
}

.pr-review-engine-min-width-690px .pr-pagination-bottom {
  overflow: hidden;
}

.pr-review-engine-min-width-690px .pr-pagination-bottom .pr-page-count-wrapper,
.pr-review-engine-min-width-690px .pr-pagination-bottom .pr-fb-follow-wrapper {
  float: left;
  width: 33%;
}

.pr-review-engine-min-width-690px .pr-pagination-bottom .pr-page-nav-wrapper {
  float: right;
}

.pr-review-engine-min-width-690px .pr-pagination-bottom .pr-page-count-wrapper {
  text-align: left;
}

.pr-review-engine-min-width-690px .pr-pagination-bottom .pr-page-nav-wrapper {
  text-align: right;
  margin: 0;
}

.pr-review-engine-min-width-690px .pr-other-attributes-histogram .pr-other-attributes-group {
  float: left;
  width: 48%;
  margin-right: 4%;
}

.pr-review-engine-min-width-690px .pr-other-attributes-histogram .pr-other-attributes-group:nth-child(2n) {
  margin-right: 0;
}

.pr-review-engine-min-width-690px .pr-other-attributes-histogram .pr-other-attribute-value-histogram-stats p {
  font-size: 12px;
}

.pr-review-engine-min-width-690px .pr-other-attributes-histogram table,
.pr-review-engine-min-width-690px .pr-other-attributes-histogram tbody,
.pr-review-engine-min-width-690px .pr-other-attributes-histogram .pr-other-attribute-value-histogram-element,
.pr-review-engine-min-width-690px .pr-other-attributes-histogram .pr-other-attribute-value {
  width: 100%;
}

.pr-review-engine-min-width-690px .pr-snapshot-fb-get-advice-container,
.pr-review-engine-min-width-690px .pr-snapshot-fb-follow-product-category-button-wrapper {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.prPaHeader .prPaQuestionPrompt {
  font-family: arial;
  font-size: 85%;
  color: #000;
  background: transparent;
  border-top: none;
  padding: 0em 0em 0em 0em;
  /* L/R padding must match prPaContainer */
}

.pr-review-engine-min-width-560px-max-width-690px a,
.pr-review-engine-max-width-560px a {
  color: #4a99ca;
  font-weight: normal;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaContainer,
.pr-review-engine-max-width-560px .prPaContainer {
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  padding: 0.5em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaQ,
.pr-review-engine-min-width-560px-max-width-690px .prPaA,
.pr-review-engine-max-width-560px .prPaQ,
.pr-review-engine-max-width-560px .prPaA {
  font-size: 120%;
  word-wrap: break-word;
  color: #303533;
  font-size: 120%;
  padding-left: 0.2em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaAnswerList,
.pr-review-engine-max-width-560px .prPaAnswerList {
  margin-left: 0px;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaAnswerList li,
.pr-review-engine-max-width-560px .prPaAnswerList li {
  border: none;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaContainer li,
.pr-review-engine-max-width-560px .prPaContainer li {
  margin-left: 0;
  border: none;
  border-top: 1px solid #d7d7d7;
  margin-bottom: 1em;
  padding: 1.5em 0em 0 0em;
  background-color: transparent;
  list-style-type: none;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaCounts,
.pr-review-engine-max-width-560px .prPaCounts {
  padding-top: 0em;
  border-top: none;
  padding-top: 1em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaPagination,
.pr-review-engine-max-width-560px .prPaPagination {
  line-height: 1;
  color: #444;
  font-size: 0.95em;
  position: relative;
  margin: 0.9em 0;
  border-top: 1px solid #d7d7d7;
  padding-top: 1em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaPagination ~ .prPaPagination,
.pr-review-engine-max-width-560px .prPaPagination ~ .prPaPagination {
  display: block;
  padding-top: 1.5em;
  padding-bottom: 0.6em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaByLine,
.pr-review-engine-max-width-560px .prPaByLine {
  float: none;
  margin: 0.7em 0 0 0.3em;
  font-size: 85%;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaQuestionPromptIntro,
.pr-review-engine-max-width-560px .prPaQuestionPromptIntro {
  display: none;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaFooter,
.pr-review-engine-max-width-560px .prPaFooter {
  background: transparent;
  padding: 0.5em 0em 0em 0em;
  border: none;
  display: block;
  width: 100%;
}

.pr-review-engine-min-width-560px-max-width-690px p.prPaAnswerLnk,
.pr-review-engine-max-width-560px p.prPaAnswerLnk {
  float: none;
  display: block;
  padding: 0.3em 0em 0.7em 2.4em;
  color: #767676;
  font-size: 92%;
}

.pr-review-engine-min-width-560px-max-width-690px p.prPaAnswerLnk a,
.pr-review-engine-max-width-560px p.prPaAnswerLnk a {
  color: #4a99ca;
  font-weight: normal;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaFooter .prPaAskQuestionLnk,
.pr-review-engine-max-width-560px .prPaFooter .prPaAskQuestionLnk {
  background: #f3f3f3;
  display: block !important;
  float: none;
  border: 1px solid #d7d7d7;
  margin: 0.4em 0em 2em 0em;
  padding: 0.4em;
  height: auto;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaFooter .prPaAskQuestionLnk span,
.pr-review-engine-max-width-560px .prPaFooter .prPaAskQuestionLnk span {
  background: transparent;
  text-align: center;
  color: #4a99ca;
  font-size: 1.1em;
  padding-left: 0em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaAddBorder,
.pr-review-engine-max-width-560px .prPaAddBorder {
  padding-bottom: 0.7em;
  border: none;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaPageCount,
.pr-review-engine-min-width-560px-max-width-690px .prPaPageNav,
.pr-review-engine-max-width-560px .prPaPageCount,
.pr-review-engine-max-width-560px .prPaPageNav {
  color: #909093;
}

.pr-review-engine-min-width-560px-max-width-690px p.prPaTotalsLabel,
.pr-review-engine-max-width-560px p.prPaTotalsLabel {
  font-weight: bold;
  color: #4a99ca;
  cursor: pointer;
  padding-left: 12px;
  margin-bottom: 0.5em;
  margin-left: 2.2em;
}

.pr-review-engine-min-width-560px-max-width-690px p.paPrQaPrefix,
.pr-review-engine-max-width-560px p.paPrQaPrefix {
  font-weight: bold;
  margin: 0 0.6em 0 -2em;
  padding: 0;
  display: inline;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaQaDialog,
.pr-review-engine-max-width-560px .prPaQaDialog {
  padding-left: 2em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaAnswerDialog .prPaByLine,
.pr-review-engine-max-width-560px .prPaAnswerDialog .prPaByLine {
  margin: 0em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaAnswerCont,
.pr-review-engine-max-width-560px .prPaAnswerCont {
  padding-left: 1.3em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaBadgeCase,
.pr-review-engine-max-width-560px .prPaBadgeCase {
  margin: 0 0 0.5em -2em;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaToggleClosed,
.pr-review-engine-max-width-560px .prPaToggleClosed {
  background: transparent url(images/pa_carat_blue_closed.gif) 0 0.29em no-repeat;
}

.pr-review-engine-min-width-560px-max-width-690px .prPaToggleOpen,
.pr-review-engine-max-width-560px .prPaToggleOpen {
  background: transparent url(images/pa_carat_blue_open.gif) 0 0.4em no-repeat;
}

@media (max-width: 520px) {
  .pr-review-engine .pr-base-modal .pr-fb-follow-email-input,
  .pr-base-modal .pr-fb-follow-email-input {
    margin-bottom: 20px;
  }
  .pr-review-engine .pr-base-modal .pr-fb-follow-content,
  .pr-base-modal .pr-fb-follow-content {
    text-align: center;
  }
}

@media (min-width: 521px) {
  .pr-review-engine .pr-base-modal .pr-profile-review-product-info a,
  .pr-base-modal .pr-profile-review-product-info a {
    display: block;
    float: left;
    margin-right: 20px;
    line-height: 100px;
  }
  .pr-review-engine .pr-base-modal .pr-profile-review-product-info a img,
  .pr-base-modal .pr-profile-review-product-info a img {
    width: 100px;
    height: 100px;
  }
}

@media (min-width: 521px) and (max-width: 1000px) {
  .pr-review-engine .pr-base-modal,
  .pr-base-modal {
    width: 500px !important;
    margin-left: -250px;
  }
  .pr-review-engine .pr-base-modal .pr-fb-follow-content,
  .pr-base-modal .pr-fb-follow-content {
    text-align: center;
  }
}

@media (min-width: 1001px) {
  .pr-review-engine .pr-base-modal,
  .pr-base-modal {
    height: auto !important;
    width: 980px !important;
    margin-left: -490px;
    top: 50% !important;
    bottom: auto !important;
    margin-top: -250px !important;
  }
  .pr-review-engine .pr-base-modal .pr-profile-author-container,
  .pr-base-modal .pr-profile-author-container {
    float: left;
    width: 30%;
    margin-right: 5%;
  }
  .pr-review-engine .pr-base-modal .pr-profile-reviews-wrapper,
  .pr-base-modal .pr-profile-reviews-wrapper {
    border: 1px solid #cccccc;
    border-radius: 2px;
    height: calc(100% - 41px);
    box-sizing: border-box;
    overflow: auto;
    padding: 20px;
    max-height: 400px;
  }
  .pr-review-engine .pr-base-modal .pr-profile-reviews-container,
  .pr-base-modal .pr-profile-reviews-container {
    box-sizing: border-box;
    float: left;
    width: 65%;
    height: 100%;
    overflow: hidden;
  }
  .pr-review-engine .pr-base-modal .pr-review-author-date,
  .pr-base-modal .pr-review-author-date {
    float: right;
  }
  .pr-review-engine .pr-base-modal .pr-attribute-group,
  .pr-base-modal .pr-attribute-group {
    box-sizing: border-box;
    float: left;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 0;
  }
  .pr-review-engine .pr-base-modal .pr-attribute-group.pr-last,
  .pr-base-modal .pr-attribute-group.pr-last {
    margin-right: 0;
  }
  .pr-review-engine .pr-base-modal .pr-last,
  .pr-base-modal .pr-last {
    margin-right: 0;
  }
  .pr-review-engine .pr-base-modal .pr-review-bottom-line,
  .pr-base-modal .pr-review-bottom-line {
    display: inline-block;
  }
  .pr-review-engine .pr-base-modal .pr-other-attribute-additional-ratings .pr-other-attribute-value,
  .pr-review-engine .pr-base-modal .pr-other-attribute-additional-ratings .pr-other-attribute-label,
  .pr-base-modal .pr-other-attribute-additional-ratings .pr-other-attribute-value,
  .pr-base-modal .pr-other-attribute-additional-ratings .pr-other-attribute-label {
    display: inline-block;
  }
  .pr-review-engine .pr-base-modal .pr-other-attribute-tag-group .pr-other-attribute-label,
  .pr-base-modal .pr-other-attribute-tag-group .pr-other-attribute-label {
    margin-bottom: 0;
  }
  .pr-review-engine .pr-base-modal .pr-other-attribute-tag-group .pr-other-attribute-value,
  .pr-base-modal .pr-other-attribute-tag-group .pr-other-attribute-value {
    margin-bottom: 5px;
  }
  .pr-review-engine .pr-base-modal .pr-other-attribute-label,
  .pr-review-engine .pr-base-modal .pr-review-bottom-line,
  .pr-base-modal .pr-other-attribute-label,
  .pr-base-modal .pr-review-bottom-line {
    margin-right: 10px;
  }
  .pr-review-engine .pr-base-modal .pr-fb-follow-email-input,
  .pr-base-modal .pr-fb-follow-email-input {
    width: 250px;
  }
  .pr-review-engine .pr-base-modal .pr-fb-follow-content-wrapper > .pr-fb-follow-info,
  .pr-base-modal .pr-fb-follow-content-wrapper > .pr-fb-follow-info {
    float: left;
    margin-right: 100px;
    overflow: hidden;
  }
}
