#pr-profile {
  box-sizing: border-box !important;
  position: fixed !important;
  border: 10px solid rgba(118, 118, 118, 0.3) !important;
  border-radius: 2px !important;
  background: #ffffff !important;
  height: auto !important;
  width: 700px !important;
  margin-left: -350px !important;
  top: 50% !important;
  left: 50% !important;
  bottom: auto !important;
  margin-top: -250px !important;

  .pr-modal-container {
    position: static !important;
  }

  .pr-modal-shadow {
    display: none !important;
  }
}

#pr-profile .pr-modal-loading {
  width: 100% !important;
}

// sucky rules, need to use id selector in order to override
// our own styles in pufferfish
#pr-profile .pr-modal-container {
  width: auto !important;
  height: 470px;
}

@media (max-width: 751px) {
  #pr-profile {
    width: 300px !important;
    margin-left: -150px !important;
  }

  #pr-profile .pr-user {
    height: 140px;
  }

  #pr-profile .pr-tab-content {
    height: 249px;
  }

  #pr-profile .pr-histogram-container {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-bottom: 10px;
  }

  #pr-profile ul.pr-stats {
    width: 100%;
  }

  #pr-profile {
    li.pr-review,
    li.pr-answer {
      .pr-thumb {
        width: auto;
        float: none;
      }

      .pr-review-main,
      .pr-answer-main {
        width: auto;
        float: none;
        padding-right: 0;
      }
    }

    li.pr-review {
      .pr-tags {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      h3 {
        margin-bottom: 0;
      }
    }
  }
}
