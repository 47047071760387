@mixin horizontal-attribute-group {
  box-sizing: border-box;
  float: left;
  width: 32%;
  margin-right: 2%;
  margin-bottom: 0;

  &.pr-last {
    margin-right: 0;
  }
}

@mixin collapsed-horizontal-attribute-group {
  box-sizing: border-box;
  float: left;
  width: 33%;
  margin-bottom: 0;
}
