// Placeholders
%headline {
  font-weight: bold;
  color: $headline-color;
  line-height: 1.2;
}

%headline-1,
%headline-2,
%headline-3,
%headline-4,
%headline-5,
%headline-6 {
  @extend %headline;
}

%headline-1 {
  font-size: $headline-1-font-size;
}

%headline-2 {
  font-size: $headline-2-font-size;
}

%headline-3 {
  font-size: $headline-3-font-size;
}

%headline-4 {
  font-size: $headline-4-font-size;
}

%headline-5 {
  font-size: $headline-5-font-size;
}

%headline-6 {
  font-size: $headline-6-font-size;
}

%body-copy {
  color: $body-copy-color;
  line-height: $body-copy-line-height;
}

%body-copy-small,
%body-copy-regular,
%body-copy-large {
  @extend %body-copy;
}

%body-copy-small {
  font-size: $body-copy-small-font-size;
}

%body-copy-regular {
  font-size: $body-copy-regular-font-size;
}

%body-copy-large {
  font-size: $body-copy-large-font-size;
}
