.pr-snapshot-fb-get-advice-container,
.pr-snapshot-fb-follow-product-category-button-wrapper {
  background-color: $light-blue-background-color;
  border-radius: $default-border-radius;
  padding: $medium-spacing;
  margin-bottom: $large-spacing;
  position: relative;
  width: 240px;
  box-sizing: border-box;

  &:before {
    display: block;
    content: '';
    position: absolute;
    right: $medium-spacing;
  }
}

.pr-snapshot-fb-get-advice-container {
  &:before {
    background: url('images/reviews-comments-facebook-icon_01.png') no-repeat;
    background-size: 16px 16px;
    margin-top: $medium-spacing;
    width: 16px;
    height: 16px;
  }
}

.pr-snapshot-fb-follow-product-category-button-wrapper {
  &:before {
    background: url('images/reviews-subscribe-mail-icon_01.png') no-repeat;
    background-size: 22px 16px;
    margin-top: $medium-spacing;
    width: 22px;
    height: 16px;
  }
}

.pr-snapshot-fb-get-advice-label,
.pr-snapshot-fb-follow-product-category-label {
  @extend %headline-5;
}

.pr-snapshot-fb-get-advice-link,
.pr-snapshot-fb-follow-product-category-link {
  @extend %body-copy-regular;
}
