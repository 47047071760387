%clickable-histogram-barValue,
%histogram-barValue {
  border-radius: $default-border-radius;
  background: $histogram-bar-background-color;
}

%clickable-histogram-bar,
%histogram-bar {
  display: inline-block;
  border-radius: $default-border-radius;
  vertical-align: middle;
  background: $grey-background-color;
}

%clickable-histogram-bar {
  box-shadow: inset 0 0 3px #aaaaaa;
}

%clickable-histogram-barValue {
  background: linear-gradient(#f4ad58, #f7941d);
  transition: all 0.25s ease;
}

%histogram-barValue {
  height: $histogram-bar-height;
}

%histogram-bar {
  height: $histogram-bar-height;
}
