// Modals
.pr-review-engine .pr-base-modal,
.pr-base-modal {
  position: fixed;
  box-sizing: border-box;
  // TODO MCA: Markup bandaid
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  border: 10px solid $modal-border-color;
  background-clip: padding-box;
  border-radius: $default-border-radius;
  background: $white-color;
  margin-left: -300/2 + px;
  top: 5% !important;
  height: auto !important;
  max-height: 90%;
  overflow: auto;
  z-index: 9999;

  // TODO MCA: Markup bandaid
  width: 300px !important;

  &.pr-hidden,
  .pr-hidden {
    display: none;
  }

  .pr-modal-container {
    position: static;
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    height: 340px;
    overflow: auto;
    padding: $large-spacing;
  }

  // TODO MCA: Markup bandaid
  .pr-modal-shadow {
    display: none;
  }

  .pr-profile-nickname {
    @extend %headline-1;
  }

  .pr-profile-location {
    @extend %body-copy-large;
  }

  .pr-profile-author-information {
    margin-bottom: $extra-large-spacing;
  }

  .pr-ratings-histogram-content > li {
    margin-bottom: $small-spacing;
  }

  .pr-histogram-label,
  .pr-histogram-count {
    display: inline-block;
  }

  .pr-ratings-histogram-bar {
    @extend %histogram-bar;
    width: 150px;
  }

  .pr-ratings-histogram-barValue {
    @extend %histogram-barValue;
  }

  .pr-profile-histogram-header {
    @extend %headline-4;
    margin-bottom: $medium-spacing;
  }

  .pr-profile-histogram-container {
    margin-bottom: $extra-large-spacing;
  }

  .pr-histogram-label,
  .pr-histogram-count {
    font-size: $body-copy-small-font-size;
  }

  .pr-histogram-label {
    padding-right: $large-spacing;
    white-space: nowrap;
  }

  .pr-histogram-count {
    padding-left: $large-spacing;
    width: 50px;
  }

  .pr-review-author-affinities {
    // TODO MCA: Markup bandaid
    @extend %headline-4;

    span {
      @extend %body-copy-large;
      font-weight: normal;
    }
  }

  .pr-review-author-affinity-wrapper,
  .pr-profile-fb-manage-settings {
    margin-bottom: $large-spacing;
  }

  .pr-profile-reviews-header {
    @extend %headline-3;
    margin-bottom: $extra-large-spacing;
  }

  .pr-profile-review-product-info {
    *:last-child {
      display: block;
      margin-bottom: $extra-large-spacing;
    }
  }

  .pr-close {
    background: url('images/modal-close.png') no-repeat scroll left top
      transparent;
    display: block;
    height: 14px;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 16px;
    z-index: 10000;
    > span {
      display: none;
    }
  }

  .pr-fb-follow-modal-header {
    @extend %headline-2;
    margin-bottom: $medium-spacing;
  }

  .pr-fb-follow-email-input {
    border-radius: $default-border-radius;
    font-size: $body-copy-large-font-size;
    padding: $medium-spacing;
    margin-right: $large-spacing;
  }

  .pr-fb-follow-submit-btn {
    @include rounded-container;
    background: $grey-background-color;
    color: $link-default-color;
    font-size: $headline-3-font-size;
    padding: $medium-spacing;
  }

  .pr-fb-follow-info-wrapper > p {
    margin-top: $large-spacing;
    margin-bottom: $large-spacing;
  }

  .pr-fb-follow-content-wrapper > .pr-fb-follow-info {
    > .pr-emphasis {
      display: none;
    }
  }

  .pr-review-faceoff-review {
    display: none;
  }

  .pr-review-faceoff-review-full {
    display: block;
  }

  .pr-review-faceoff-title {
    margin-top: $medium-spacing;
  }
}
