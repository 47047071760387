.pr-review-engine-min-width-560px-max-width-690px,
.pr-review-engine-min-width-690px {
  .pr-customer-media-wrapper {
    float: left;
    margin-right: 40px;
  }

  .pr-review-wrap {
    .pr-attribute-group {
      @include horizontal-attribute-group;
    }
  }

  .pr-attribute-value-list-empty {
    line-height: $pr-attribute-value-horizontal-layout-height;
  }

  .pr-review-sort {
    overflow: hidden;

    .pr-review-count {
      float: left;
      margin-bottom: 0;
    }

    .pr-review-sort-box {
      float: right;
    }
  }
}
