.pr-summary-snippet {
  .pr-other-attributes-histogram {
    .pr-other-attribute-label {
      display: block;
    }
  }

  .pr-other-attribute-value-histogram-label {
    width: 140px;
    line-height: 1.4;
  }

  .pr-other-attribute-value-histogram-barValue {
    @extend %histogram-barValue;
  }

  .pr-other-attribute-value-histogram-wrapper {
    @extend %histogram-bar;
    width: 100%;
  }

  .pr-other-attribute-value-histogram-bar,
  .pr-other-attribute-value-histogram-stats {
    padding-left: $extra-large-spacing;
  }

  .pr-other-attribute-value-histogram-stats {
    width: 1%;
    padding-right: 8%;
    white-space: nowrap;

    .pr-snippet-review-count {
      display: none;
    }

    p {
      display: inline;
    }
  }
}
