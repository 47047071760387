.pr-review-engine {
  @import 'modules/_review_filter.scss';
  @import 'modules/_snapshot_buttons.scss';
  @import 'modules/_snapshot_histogram.scss';
  padding: $medium-spacing;

  .pr-attribute-collapse {
    display: block;
  }

  .pr-review-fb-social-loyalty {
    margin-bottom: $small-spacing;
  }

  .pr-review-social-bar {
    margin-bottom: $extra-large-spacing;
  }

  .pr-snapshot-title {
    @extend %headline-1;
    display: inline-block;
    margin-right: $medium-spacing;
    margin-bottom: $medium-spacing;
  }

  .pr-snapshot-cobrand {
    display: block;
  }

  .pr-snapshot-head {
    background: none;
  }

  .pr-snapshot-head-wrapper {
    border-bottom: $default-border;
    margin-bottom: $extra-large-spacing;
    padding-bottom: $extra-large-spacing;
  }

  .pr-snapshot-product-name {
    display: none;
  }

  .pr-snapshot-rating {
    position: relative;
    .pr-rating {
      margin-right: $small-spacing;
    }
  }

  .pr-snapshot-average-based-on-text {
    @extend %headline-5;
    margin-bottom: $medium-spacing;
  }

  .pr-snapshot-consensus {
    margin-bottom: $extra-large-spacing;
  }

  .pr-snapshot-consensus-value {
    @extend %headline-4;
    color: $white-color;
    background: $pr-snapshot-consensus-background-color;
    display: inline-block;
    border-radius: $default-border-radius;
    padding: 4px 8px;
    margin-bottom: $small-spacing;
  }

  .pr-snapshot-consensus-text {
    @extend %body-copy-small;
    display: inline-block;
  }

  .pr-snapshot-footer {
    clear: both;
    margin-bottom: $extra-large-spacing;
  }

  .pr-snapshot-write-review-social-loyalty {
    margin-top: $small-spacing;
  }

  .pr-snapshot {
    .pr-attribute-label-pros,
    .pr-attribute-label-cons,
    .pr-attribute-label-bestuses,
    .pr-attribute-label-rating {
      padding: $large-spacing;
    }

    @if $clickable-histogram-enabled == true {
      .pr-attribute-ratings {
        display: block !important;
      }

      .pr-attribute-cons {
        margin-right: 0;
      }

      .pr-attribute-bestuses {
        display: none;
      }
    }

    .pr-attribute-group {
      @include rounded-container;
    }

    .pr-attribute-key {
      background: $grey-background-color;
      &,
      & * {
        border-top-left-radius: $default-border-radius;
        border-top-right-radius: $default-border-radius;
      }
    }
  }

  .pr-last {
    margin-bottom: 0;
  }

  .pr-snapshot-attribute-value-list {
    padding: $large-spacing !important;
  }

  .pr-other-attributes {
    clear: both;
  }

  .pr-other-attributes-list,
  .pr-other-attributes-list ul {
    list-style: none;
    padding-left: 0;

    .pr-other-attributes-group {
      overflow: hidden;
      margin-bottom: $extra-large-spacing;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pr-write-review-link {
    font-size: $headline-3-font-size;
    font-weight: bold;
  }

  // Faceoff
  .pr-review-faceoff {
    @include rounded-container;
    margin-bottom: $extra-large-spacing;

    .pr-stars {
      margin-bottom: $medium-spacing;
    }
  }

  .pr-review-faceoff-review-full {
    display: none;
  }

  .pr-review-faceoff-label {
    @extend %headline-5;
    margin-bottom: $medium-spacing;
  }

  .pr-review-faceoff-title {
    @extend %headline-3;
    margin-bottom: $small-spacing;
  }

  .pr-review-faceoff-review-positive,
  .pr-review-faceoff-review-negative {
    padding: $extra-large-spacing;
  }

  .pr-review-faceoff-img {
    border-bottom: $default-border;
    float: left;
    height: 1px;
    top: 50%;
    text-align: center;
    width: 100%;
    margin-bottom: $extra-large-spacing + 10px;
    margin-top: $extra-large-spacing - 10px;
  }

  .pr-review-faceoff-img-txt {
    @extend %headline-4;
    @include rounded-container;
    display: inline-block;
    background: $white-color;
    padding: 8px 12px;
    margin-top: -17px;
  }

  .pr-review-faceoff-readcomplete {
    display: block;
  }

  .pr-review-faceoff-readcomplete {
    margin-top: $medium-spacing;
  }

  // TODO MCA: Markup bandaid?
  .pr-review-content-header {
    display: none;
  }

  .pr-ratings-histogram-content {
    padding: 0px !important;
  }

  // Sort bar
  .pr-review-sort {
    background: $grey-background-color;
    text-align: center;
    padding: $large-spacing;
    margin-bottom: $extra-large-spacing;
    width: 100%;
    box-sizing: border-box;
  }

  .pr-review-count {
    @extend %headline-4;
    margin-bottom: $medium-spacing;
  }

  .pr-review-sort-by {
    @extend %headline-5;
    display: inline-block;
    margin-right: $small-spacing;
  }

  .pr-review-sort-box select {
    @extend %body-copy-regular;
    @include rounded-container;
    background: $white-color;
  }

  // Pagination top
  .pr-pagination-top {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }

  .pr-page-nav-wrapper {
    margin-top: $medium-spacing;
    font-size: $body-copy-large-font-size;
  }

  .pr-page-count,
  .pr-nav-back-to-top {
    display: inline-block;
  }

  .pr-page-count {
    margin-right: $medium-spacing;
  }

  // Reviews
  .pr-review-most-helpful {
    p {
      @extend %body-copy-small;
      margin-bottom: $medium-spacing;
    }
  }

  .pr-review-most-helpful-bottom {
    display: none;
  }

  .pr-review-rating-wrapper,
  .pr-review-author-info-wrapper {
    margin-bottom: $extra-large-spacing;
  }

  .pr-pagination-bottom {
    text-align: center;
    border-bottom: $default-border;
    border-top: $default-border;
    padding-top: $extra-large-spacing;
    margin-bottom: $extra-large-spacing;
    padding-bottom: $extra-large-spacing;
  }

  .pr-snapshot-average-based-on-text {
    display: inline-block;
  }
  .pr-disclosure-message {
    padding: 5px;
    background-color: #eee;
    font-style: italic;
    margin: 15px 0;
    .pr-disclosure-text {
      color: #767676;
    }
  }
}
