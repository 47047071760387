.pr-base-modal,
.pr-review-engine {
  .pr-closed {
    display: none;
  }

  .pr-hidden {
    visibility: hidden;
  }

  .pr-snapshot-change-locale-wrapper {
    margin-bottom: $medium-spacing;
  }

  .pr-rating,
  .pr-review-author-date {
    @extend %body-copy-regular;
    @include rounded-container;
    display: inline-block;
    background: $white-color;
    padding: 1px 6px;
    margin-bottom: $medium-spacing;
    height: 20px;
  }

  .pr-review-rating-headline {
    @extend %headline-2;
  }

  .pr-review-author-name,
  .pr-review-author-location,
  .pr-review-author-affinities {
    @extend %headline-5;
    span {
      line-height: $body-copy-line-height;
      font-weight: normal;
    }
  }

  .pr-review-author-follow-link-container {
    margin-top: $extra-large-spacing;
  }

  .pr-review-author {
    margin-bottom: $extra-large-spacing;
  }

  .pr-review-wrap {
    border-bottom: $default-border;
    margin-bottom: $extra-large-spacing;

    .pr-attribute-key {
      margin-bottom: $small-spacing;

      p {
        background-color: $grey-background-color;
        padding: $small-spacing;
        font-size: $body-copy-regular-font-size;
        border-radius: $default-border-radius;
      }
    }

    .pr-attribute-value-list {
      margin-left: $small-spacing;
      li {
        list-style: none !important;
        font-size: $body-copy-regular-font-size;
      }
    }

    .pr-review-footer {
      clear: both;
    }
  }

  // Crazy rule having to resort to since last-of-type is not working for pr-review-wrap; css bug
  .pr-review-wrap + .pr-pagination-bottom {
    border-top: 0;
    padding-top: 0;
  }

  .pr-attribute-group {
    margin-bottom: $extra-large-spacing;
  }

  .pr-review-points {
    overflow: hidden;
    clear: both;
    margin-bottom: $extra-large-spacing;
  }

  .pr-other-attributes-histogram {
    .pr-other-attribute-label {
      display: block;
    }
  }

  .pr-other-attribute-value-histogram-label {
    width: 140px;
    line-height: 1.4;
  }

  .pr-other-attribute-value-histogram-barValue {
    @extend %histogram-barValue;
  }

  .pr-other-attribute-value-histogram-wrapper {
    @extend %histogram-bar;
    width: 100%;
  }

  .pr-other-attribute-value-histogram-bar,
  .pr-other-attribute-value-histogram-stats {
    padding-left: $extra-large-spacing;
  }

  .pr-other-attribute-value-histogram-stats {
    width: 1%;
    padding-right: 8%;
    white-space: nowrap;

    .pr-snippet-review-count {
      display: none;
    }

    p {
      display: inline;
    }
  }

  .pr-comments-header {
    display: none;
  }

  .pr-comments {
    @extend %body-copy-large;
    clear: both;
    margin-bottom: $extra-large-spacing;
  }

  .pr-pic-content {
    @include rounded-container;
    margin-bottom: $small-spacing;
  }

  .pr-customer-media {
    overflow: hidden;
  }

  .pr-customer-media-user {
    margin-bottom: $small-spacing;
  }

  .pr-caption {
    margin-bottom: $medium-spacing;
  }

  .pr-caption {
    @extend %headline-5;
  }

  .pr-pic-content {
    // @extend %rounded-container;
    display: inline-block;
    padding: 2px;
  }

  .pr-pic-tags {
    display: none;
  }

  .pr-customer-media-wrapper {
    margin-bottom: $large-spacing;

    .wrap2 {
      line-height: 0;
    }
  }

  .pr-review-bottom-line {
    @extend %headline-5;
    display: block;
    margin-bottom: $small-spacing;
  }

  .pr-other-attribute-tag-group {
    margin-bottom: $extra-large-spacing;
  }

  .pr-review-bottom-line-wrapper {
    margin-bottom: $extra-large-spacing;
  }

  .pr-review-helpful-text {
    display: inline-block;
    font-weight: bold;
    color: $headline-color;
    a {
      font-weight: normal;
    }
  }

  .pr-review-report-issue {
    display: block;
  }

  .pr-review-report-issue-hyphen {
    display: none;
  }

  .pr-attribute-label-pros,
  .pr-attribute-label-cons,
  .pr-attribute-label-bestuses,
  .pr-attribute-label-rating {
    @extend %headline-4;
    margin: 0;
  }

  .pr-attribute-value-list {
    list-style: none;
    padding-left: 0;

    li {
      @extend %body-copy-regular;
      list-style: none !important;
      margin-bottom: $small-spacing;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pr-other-attribute-label {
    @extend %headline-5;
    margin-bottom: $small-spacing;
  }

  .pr-review-main-wrapper {
    margin-bottom: $extra-large-spacing;
  }

  .pr-fb-reviewComment,
  .pr-fb-review-comment {
    border-top: $default-border;
    margin-bottom: $extra-large-spacing;
    padding-top: $extra-large-spacing;

    &:before {
      background: url('images/reviews-comments-facebook-icon_01.png') no-repeat;
      background-size: 16px 16px;
      margin-right: $small-spacing;
      width: 16px;
      height: 16px;
      content: '';
      display: inline-block;
      vertical-align: middle;
    }

    span {
      margin-right: $small-spacing;
      vertical-align: middle;
    }
  }

  .pr-contents-wrapper {
    .pr-review-wrap:last-of-type {
      border-bottom: $default-border;
    }
  }

  // TODO MCA: Markup bandaid
  .pr-profile-fb-help-icon {
    display: none;
  }

  .pr-review-rating-wrapper {
    margin-bottom: $medium-spacing;
  }

  .pr-review-attribution-img {
    margin-bottom: $extra-large-spacing;
  }

  .pr-review-attribute-imgLabel {
    font-size: $body-copy-small-font-size;
  }

  .syndication-logo-lnk img {
    width: 70px;
  }

  .pr-review-author-badge-wrapper {
    > * {
      margin-bottom: $medium-spacing;

      *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
