#pr-snapshot-histogram {
  display: inline-block;
  margin-left: $small-spacing;
}

.pr-ratings-histogram-bar {
  @if $clickable-histogram-enabled == true {
    @extend %clickable-histogram-bar;
  } @else {
    @extend %histogram-bar;
  }
}

.pr-ratings-histogram-barValue {
  @if $clickable-histogram-enabled == true {
    @extend %clickable-histogram-barValue;
  } @else {
    @extend %histogram-barValue;
  }
}

.pr-selected {
  .pr-ratings-histogram-barValue {
    background: linear-gradient(#e99b3e, #cf7912);
  }
}

.pr-selected {
  .pr-ratings-histogram-bar {
    background: #ddd;
  }
}

.pr-attribute-value-list-empty {
  font-size: $body-copy-regular-font-size;
  text-align: center;
  color: $not-selected-color;
}

@if $clickable-histogram-enabled == true {
  #pr-snapshot-histogram {
    display: none;
  }
}

#pr-snapshot-histogram-container {
  @include rounded-container;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 245px;
  margin-top: $medium-spacing;
  padding: $large-spacing;
  background: $white-color;
  z-index: 9999;

  .pr-ratings-histogram-bar {
    @extend %histogram-bar;
    width: 148px;
  }

  .pr-ratings-histogram-barValue {
    @extend %histogram-barValue;
  }
}

#pr-snapshot-histogram-header {
  @extend %headline-4;
  margin-bottom: $small-spacing;
}

.pr-histogram-5Stars,
.pr-histogram-4Stars,
.pr-histogram-3Stars,
.pr-histogram-2Stars,
.pr-histogram-1Stars {
  display: table;
  width: 100%;
  margin-bottom: $small-spacing;
  @if $clickable-histogram-enabled == true {
    cursor: pointer;

    &:hover {
      .pr-ratings-histogram-barValue {
        background: linear-gradient(#e99b3e, #cf7912);
      }

      .pr-ratings-histogram-bar {
        background: #ddd;
      }
    }

    &.pr-zero-ratings {
      .pr-histogram-label,
      .pr-histogram-count {
        color: #ccc;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  > * {
    display: table-cell;
  }
}

.pr-histogram-label,
.pr-histogram-count {
  font-size: $body-copy-small-font-size;
}

.pr-histogram-label {
  @if $clickable-histogram-enabled == true {
    font-size: $body-copy-regular-font-size;
    color: $link-default-color;
  }
  padding-right: $large-spacing;
  white-space: nowrap;
  width: 40px;
}

.pr-histogram-count {
  padding-left: $medium-spacing;
  width: 30px;
}
