.pr-review-engine-min-width-560px-max-width-690px {
  .pr-snapshot {
    .pr-attribute-group {
      @include collapsed-horizontal-attribute-group;
    }

    .pr-attribute-value {
      box-sizing: border-box;
      height: 160px;
    }

    .pr-attribute-value-list {
      padding: $medium-spacing;
      li {
        font-size: $body-copy-small-font-size;
      }
    }

    @if $clickable-histogram-enabled == true {
      .pr-attribute-pros {
        border-left: 0;
        border-right: 0;
        border-radius: 0;

        .pr-attribute-key {
          border-radius: 0;
        }
      }

      .pr-attribute-ratings {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        .pr-attribute-key {
          border-top-right-radius: 0;
        }
      }
    } @else if {
      .pr-attribute-cons {
        border-left: 0;
        border-right: 0;
        border-radius: 0;

        .pr-attribute-key {
          border-radius: 0;
        }
      }

      .pr-attribute-pros {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .pr-attribute-bestuses {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .pr-attribute-cons {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      .pr-attribute-key {
        border-top-left-radius: 0;
      }
    }

    .pr-attribute-label-pros,
    .pr-attribute-label-cons,
    .pr-attribute-label-bestuses,
    .pr-attribute-label-rating {
      padding: $medium-spacing;
    }
  }
  .pr-review-author-name,
  .pr-review-author-location,
  .pr-review-author-affinities {
    display: inline;
    &:after {
      content: ' ';
    }
  }
  .pr-review-author-location {
    @extend %body-copy-regular;
    font-weight: normal;
  }
}
