$qa-responsive-link-color: #4a99ca;
$qa-responsive-border-color: #d7d7d7;

.prPaHeader .prPaQuestionPrompt {
  font-family: arial;
  font-size: 85%;
  color: #000;
  background: transparent;
  border-top: none;
  padding: 0em 0em 0em 0em;
  /* L/R padding must match prPaContainer */
}

.pr-review-engine-min-width-560px-max-width-690px,
.pr-review-engine-max-width-560px {
  a {
    color: $qa-responsive-link-color;
    font-weight: normal;
  }
  .prPaContainer {
    width: 100%;
    background: transparent;
    box-sizing: border-box;
    padding: 0.5em;
  }
  .prPaQ,
  .prPaA {
    font-size: 120%;
    word-wrap: break-word;
    color: #303533;
    font-size: 120%;
    padding-left: 0.2em;
  }
  .prPaAnswerList {
    margin-left: 0px;
    li {
      border: none;
    }
  }
  .prPaContainer {
    li {
      margin-left: 0;
      border: none;
      border-top: 1px solid $qa-responsive-border-color;
      margin-bottom: 1em;
      padding: 1.5em 0em 0 0em;
      background-color: transparent;
      list-style-type: none;
    }
  }
  .prPaCounts {
    padding-top: 0em;
    border-top: none;
    padding-top: 1em;
  }
  .prPaPagination {
    line-height: 1;
    color: #444;
    font-size: 0.95em;
    position: relative;
    margin: 0.9em 0;
    border-top: 1px solid $qa-responsive-border-color;
    padding-top: 1em;
    // display: none;
    & ~ .prPaPagination {
      display: block;
      padding-top: 1.5em;
      padding-bottom: 0.6em;
    }
  }
  .prPaByLine {
    float: none;
    margin: 0.7em 0 0 0.3em;
    font-size: 85%;
  }
  .prPaQuestionPromptIntro {
    display: none;
  }
  .prPaFooter {
    background: transparent;
    padding: 0.5em 0em 0em 0em;
    border: none;
    display: block;
    width: 100%;
  }
  p.prPaAnswerLnk {
    float: none;
    display: block;
    padding: 0.3em 0em 0.7em 2.4em;
    color: #767676;
    font-size: 92%;
    a {
      color: $qa-responsive-link-color;
      font-weight: normal;
    }
  }
  .prPaFooter .prPaAskQuestionLnk {
    background: #f3f3f3;
    display: block !important;
    float: none;
    border: 1px solid $qa-responsive-border-color;
    margin: 0.4em 0em 2em 0em;
    padding: 0.4em;
    height: auto;
    span {
      background: transparent;
      text-align: center;
      color: $qa-responsive-link-color;
      font-size: 1.1em;
      padding-left: 0em;
    }
  }
  .prPaAddBorder {
    padding-bottom: 0.7em;
    border: none;
  }
  .prPaPageCount,
  .prPaPageNav {
    color: #909093;
  }
  p.prPaTotalsLabel {
    font-weight: bold;
    color: $qa-responsive-link-color;
    cursor: pointer;
    padding-left: 12px;
    margin-bottom: 0.5em;
    margin-left: 2.2em;
  }
  p.paPrQaPrefix {
    font-weight: bold;
    margin: 0 0.6em 0 -2em;
    padding: 0;
    display: inline;
  }
  .prPaQaDialog {
    padding-left: 2em;
  }
  .prPaAnswerDialog .prPaByLine {
    margin: 0em;
  }
  .prPaAnswerCont {
    padding-left: 1.3em;
  }
  .prPaBadgeCase {
    margin: 0 0 0.5em -2em;
  }
  .prPaToggleClosed {
    background: transparent url(images/pa_carat_blue_closed.gif) 0 0.29em
      no-repeat;
  }
  .prPaToggleOpen {
    background: transparent url(images/pa_carat_blue_open.gif) 0 0.4em no-repeat;
  }
}
