// Base Styles shared between modal and review display
.pr-base-modal,
.pr-review-engine,
.pr-snippet,
.pr-pa-snippet,
.prImageSnippet {
  @include css-reset;

  font-family: arial, sans-serif;

  a {
    color: $link-default-color;
    text-decoration: none;

    &:hover {
      color: $link-active-color;
    }
  }

  p,
  ul,
  li {
    @extend %body-copy-regular;
    margin: 0;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  table,
  tr,
  td {
    border-spacing: 0;
  }
}
