.pr-pa-snippet {
  .pr-pa-snippet-title,
  .pr-pa-snippet-text,
  .pr-pa-snippet-questions,
  .pr-pa-snippet-answers,
  .pr-pa-snippet-read-questions,
  .pr-pa-snippet-ask-question {
    display: inline-block;
  }

  .pr-pa-snippet-wrapper > .pr-pa-snippet-text {
    display: none;
  }

  .pr-pa-snippet-questions,
  .pr-pa-snippet-answers {
    margin-right: $medium-spacing;
  }

  .pr-pa-snippet-ask-question {
    display: inline;
    font-size: 12px;
  }

  .pr-pa-snippet-answers,
  .pr-pa-snippet-questions {
    @extend %body-copy-small;
  }

  .pr-pa-snippet-title {
    color: $headline-color;
    font-weight: bold;
    margin-right: $small-spacing;
  }

  .pr-pa-snippet-text {
    @extend %body-copy-small;
  }
}
