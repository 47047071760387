.prPaContainer,
.prPaFooter {
  width: 38.5em;
  /*550px eq*/
  font-family: arial;
  font-size: 85%;
  color: #767676;
  background: #f3f3f3;
}

.prPaContainer {
  padding: 1em;
}

.prPaContainer p {
  margin: 0;
  padding: 0;
  color: #000;
}

.prPaQ,
.prPaA {
  font-size: 114%;
}

.prPaHeader .prPaQuestionPrompt {
  padding: 0.6em 0;
  font-size: 85%;
  *font-size: 80%;
  color: #767676;
}

.prPaHeader .prPaQuestionPrompt span {
  font-weight: bold;
  color: #000000;
}

.prPaHeader .prPaQuestionPromptIntro {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.prPaHeader .prPaQuestionPromptIntro span {
  font-size: 85%;
  *font-size: 80%;
  color: #767676;
  font-weight: normal;
}

.prPaQuestionPromptIntroSLPoints {
  margin-left: 0.5em;
}

.prPaFooter {
  text-align: right;
  font-family: arial;
  font-size: 85%;
  color: #000;
  background: #f3f3f3;
  border-top: 1px solid #d7d7d7;
  padding: 0.7em 1em;
  /* L/R padding must match prPaContainer */
}

body:last-child:not(:root:root) .prPaFooter {
  /*for safari */
  padding: 0.7em 0;
}

.prPaFooter .prPaQuestionPromptIntro {
  font-weight: bold;
  margin: 0;
  padding: 0.4em 0 0 0;
}

.prPaQuestionSubhead {
  padding: 5px 0;
}

.prPaAskQuestionLnk {
  background: transparent url(images/btn_ask_question_r.png) right 0 no-repeat;
  color: #fff;
  display: block;
  float: right;
  font-weight: bold;
  height: 24px;
  margin-right: 6px;
  padding-right: 8px;
  text-decoration: none;
}

.prPaAskQuestionLnk span {
  background: transparent url(images/btn_ask_question_l.png) 0 0 no-repeat;
  display: block;
  line-height: 14px;
  padding: 5px 0 5px 18px;
  color: #fff;
  text-decoration: none;
}

.prPaQaContainer {
  margin-left: -40px;
  *margin-left: 0px;
}

.prPaContainer ul {
  list-style: none;
}

.prPaAnswerCont ul li {
  border: none;
}

.prPaContainer li {
  margin-left: 0;
  border: 1px solid #d7d7d7;
  margin-bottom: 0.5em;
  padding: 0.5em 0.5em 0 0.5em;
  background: #fff;
}

.prPaContainer .prPaAnswerList li {
  padding: 0;
}

.prPaCounts {
  padding-top: 1em;
  border-top: 1px solid #d7d7d7;
}

.prPaPagination {
  line-height: 1;
  color: #444;
  font-size: 0.95em;
  position: relative;
  margin: 0.9em 0;
}

.prPaPagination .prPaPageNav {
  position: absolute;
  right: 0;
}

.prPaQaVariantName {
  margin-bottom: 0.3em;
}

.prPaByLine {
  /*float: left;*/
  margin: 0.7em 0 0 0;
  font-size: 85%;
}

.prPaAddBorder {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 0.7em;
}

.prPaLastAnswer {
  padding-bottom: 0.5em;
}

.prPaDate {
  color: darkgray;
  display: inline;
}

.prPaQuestion {
  background-color: #eeeeff;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0.5em;
}

.prPaAnswerList {
  margin-left: -40px;
  *margin-left: 0px;
}

.prPaAnsweredIn {
  margin: 0;
  padding: 0;
  display: inline;
  font-size: 85%;
}

p.prPaTotalsLabel,
p.prPaAnswerLnk {
  margin: 1em 0 0 0;
  padding: 0 0 0.5em 0;
}

p.prPaAnswerLnk {
  font-size: 92%;
  float: right;
  color: #767676;
}

p.prPaAnswerLnk a {
  font-weight: bold;
  color: #fc961a;
}

p.prPaTotalsLabel {
  font-weight: bold;
  cursor: pointer;
  padding-left: 12px;
  margin-bottom: 0.5em;
}

.prPaSLFirstAnswer {
  font-weight: normal;
  font-size: 7pt;
  margin-left: 0.8em;
}

p.paPrQaPrefix {
  font-weight: bold;
  margin: 0 0.2em 0 0;
  padding: 0;
  display: inline;
}

.prPaBadgeCase {
  margin: 0 0 0.5em 0;
  height: 20px;
}

.prPaAnswerDialog p.prPaVerifiedMerchant {
  font-size: 77%;
  font-weight: bold;
  background: transparent url(images/badge_vm.gif) 0 0 no-repeat;
  padding-left: 25px;
  line-height: 20px;
}

.prPaAnswerDialog p.prPaVerifiedPurchaser {
  font-size: 77%;
  font-weight: bold;
  background: transparent url(images/badge_pa_vb.gif) 0 0 no-repeat;
  padding-left: 25px;
  line-height: 20px;
}

.prPaToggleClosed {
  background: transparent url(images/pa_carat_closed.gif) 0 0.4em no-repeat;
}

.prPaToggleOpen {
  background: transparent url(images/pa_carat_open.gif) 0 0.4em no-repeat;
}
