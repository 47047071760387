// Colors
$headline-color: #303533;
$body-copy-color: #666666;
$link-default-color: #4a99ca;
$link-active-color: #276891;
$border-color: #cccccc;
$pro-background-color: #70a32c;
$pr-snapshot-consensus-background-color: #84bf41;
$white-color: #ffffff;
$grey-background-color: #f6f6f6;
$verified-buyer-color: #8cc540;
$staff-expert-color: #53c7e6;
$staff-reviewer-color: #fbb817;
$verified-reviewer-color: #75c6b8;
$light-blue-background-color: #dbe6f4;
$histogram-bar-background-color: #f7941d;
$modal-border-color: rgba(118, 118, 118, 0.3);
$required-color: #dd0000;
$tertiary-button-border-color: #ddd;
$tertiary-button-hover-background-color: #f6f6f6;
$not-selected-color: #999999;

// Spacings
$small-spacing: 5px;
$medium-spacing: 10px;
$large-spacing: 15px;
$extra-large-spacing: 20px;

// Measurements
$default-border-radius: 2px;

$histogram-bar-height: 10px;

$pr-attribute-value-horizontal-layout-height: 155px;

// Font-sizes
$headline-1-font-size: 22px;
$headline-2-font-size: 20px;
$headline-3-font-size: 18px;
$headline-4-font-size: 16px;
$headline-5-font-size: 14px;
$headline-6-font-size: 12px;

$body-copy-small-font-size: 12px;
$body-copy-regular-font-size: 14px;
$body-copy-large-font-size: 16px;

$body-copy-line-height: 1.5;

// Attributes
$default-border: 1px solid $border-color;
