@media (max-width: 520px) {
  .pr-review-engine .pr-base-modal,
  .pr-base-modal {
    .pr-fb-follow-email-input {
      margin-bottom: $extra-large-spacing;
    }

    .pr-fb-follow-content {
      text-align: center;
    }
  }
}
