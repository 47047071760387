@media (min-width: 521px) and (max-width: 1000px) {
  .pr-review-engine .pr-base-modal,
  .pr-base-modal {
    width: 500px !important;
    margin-left: -250px;

    .pr-fb-follow-content {
      text-align: center;
    }
  }
}
