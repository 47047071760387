.prImageSnippet {
  .prImageSnippetImage {
    border: 1px solid #cccccc;
    float: left;
    height: 38px;
    margin-right: 1px;
    overflow: hidden;
    width: 38px;
  }

  .prImageSnippetImage img.prImageSnippetImageLandscape {
    height: 38px;
  }

  .prImageSnippetImage img {
    border: 0 none;
  }
}
