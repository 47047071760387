// Assign the star styles where we need them
.pr-review-engine,
.pr-base-modal,
.pr-snippet {
  // TODO MCA: Temporary rules
  .pr-stars,
  .pr-stars-small {
    display: inline-block;
    margin-right: $medium-spacing;
    background-repeat: no-repeat;
  }

  .pr-stars {
    height: 20px;
    width: 112px;
  }

  .pr-stars-small {
    height: 16px;
    width: 83px;
  }
}
