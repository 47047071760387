.pr-snippet {
  display: inline-block;

  .pr-snippet-rating-decimal {
    @extend %body-copy-small;
    @include rounded-container;
    vertical-align: middle;
    font-weight: bold;
    display: inline-block;
    background: $white-color;
    padding: 1px 6px;
    height: 18px;
    margin-right: $small-spacing;
  }

  .pr-snippet-review-count {
    @extend %body-copy-small;
    margin-left: $small-spacing;
    display: inline-block;
  }

  .pr-stars {
    vertical-align: middle;
  }

  .pr-snippet-stars {
    margin-bottom: $small-spacing;
  }

  .pr-snippet-stars,
  .pr-snippet-read-reviews,
  .pr-snippet-write-review {
    display: inline-block;
  }

  .pr-snippet-read-reviews,
  .pr-snippet-write-review {
    margin-right: $large-spacing;
  }

  .pr-snippet-read-reviews,
  .pr-snippet-write-review {
    @extend %body-copy-small;
  }

  .pr-snippet-read-reviews span {
    font-weight: bold;
  }

  .pr-snippet-read-write {
    display: block;
  }

  .pr-snippet-social-bar {
    margin-top: $small-spacing;
    .pr-google-plus-one {
      margin-bottom: $small-spacing;
    }
  }
}
