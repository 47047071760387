.pr-review-engine-min-width-690px {
  .pr-snapshot-title {
    margin-bottom: 0;
  }

  .pr-snapshot-cobrand {
    display: inline-block;
  }

  .pr-snapshot-rating {
    float: left;
  }

  .pr-snapshot-consensus {
    float: right;
  }

  .pr-snapshot-consensus-value {
    margin-right: $small-spacing;
  }

  .pr-attribute-group {
    @include horizontal-attribute-group;
  }

  .pr-snapshot {
    .pr-attribute-value {
      box-sizing: border-box;
      height: $pr-attribute-value-horizontal-layout-height;
    }

    .pr-last {
      margin-right: 0;
    }
  }

  .pr-last {
    margin-right: 0;
  }

  .pr-review-faceoff {
    overflow: hidden;
  }

  .pr-review-faceoff-review-positive,
  .pr-review-faceoff-review-negative {
    float: left;
    width: 44%;
    padding: 2%;
  }

  .pr-review-faceoff-review-positive {
    padding-right: 4%;
  }

  .pr-review-faceoff-review-negative {
    padding-left: 4%;
  }

  .pr-review-faceoff-img {
    top: 0;
    margin: 0;
    border-right: 1px solid #ccc;
    float: left;
    height: 100%;
    left: 50%;
    position: absolute;
    text-align: center;
    width: 1px;
  }

  .pr-review-faceoff-img-txt {
    top: 50%;
    position: absolute;
    margin-left: -23px;
  }

  .pr-review-faceoff-review-wrapper {
    position: relative;
    overflow: hidden;
  }

  .pr-review-bottom-line,
  .pr-other-attribute-label {
    float: left;
    clear: left;
    box-sizing: border-box;
    line-height: $body-copy-line-height;
  }

  .pr-other-attribute-value {
    float: left;
    width: 80%;
    line-height: $body-copy-line-height;
  }

  .pr-other-attribute-label,
  .pr-other-attribute-value,
  .pr-review-bottom-line {
    display: inline-block;
  }

  .pr-other-attribute-label,
  .pr-review-bottom-line {
    padding-right: $medium-spacing;
  }

  .pr-other-attribute-value-histogram-wrapper {
    width: 100%;
  }

  .pr-other-attributes-subratings {
    overflow: hidden;
    clear: both;

    .pr-other-attribute-label,
    .pr-other-attribute-value {
      float: none;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .pr-review-wrap {
    overflow: hidden;
  }

  .pr-review-author {
    float: left;
    width: 20%;
    margin-right: 5%;
  }

  .pr-review-rating-wrapper,
  .pr-review-main-wrapper,
  .pr-fb-reviewComment {
    float: right;
    width: 75%;
  }

  .pr-fb-reviewComment {
    clear: both;
  }

  .pr-review-author-date {
    float: right;
  }

  .pr-pagination-bottom {
    overflow: hidden;
    .pr-page-count-wrapper,
    .pr-fb-follow-wrapper {
      float: left;
      width: 33%;
    }

    .pr-page-nav-wrapper {
      float: right;
    }

    .pr-page-count-wrapper {
      text-align: left;
    }

    .pr-page-nav-wrapper {
      text-align: right;
      margin: 0;
    }
  }
  .pr-other-attributes-histogram {
    .pr-other-attributes-group {
      float: left;
      width: 48%;
      margin-right: 4%;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    .pr-other-attribute-value-histogram-stats {
      p {
        font-size: $body-copy-small-font-size;
      }
    }

    table,
    tbody,
    .pr-other-attribute-value-histogram-element,
    .pr-other-attribute-value {
      width: 100%;
    }
  }

  .pr-snapshot-fb-get-advice-container,
  .pr-snapshot-fb-follow-product-category-button-wrapper {
    display: inline-block;
    margin-bottom: 0;
    margin-right: $extra-large-spacing;
  }
}
