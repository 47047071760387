@media (min-width: 521px) {
  .pr-review-engine .pr-base-modal,
  .pr-base-modal {
    .pr-profile-review-product-info {
      a {
        display: block;
        float: left;
        margin-right: $extra-large-spacing;
        line-height: 100px;
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
