.pr-review-engine-max-width-560px {
  .pr-snapshot-consensus {
    display: table;
  }

  .pr-snapshot-consensus-text,
  .pr-snapshot-consensus-value {
    display: table-cell;
    vertical-align: middle;
  }

  .pr-snapshot-consensus-text {
    padding-left: 10px;
  }

  .pr-snapshot-rating {
    .pr-stars,
    .pr-rating,
    .pr-snapshot-average-based-on-text,
    #pr-snapshot-histogram {
      float: left;
    }
  }

  .pr-snapshot-consensus {
    clear: left;
  }

  .pr-snapshot-average-based-on-text {
    clear: left;
    font-size: $body-copy-small-font-size;
    margin-bottom: $large-spacing;
  }

  #pr-snapshot-histogram-container {
    left: 0;
  }

  .pr-other-attributes-histogram {
    .pr-snippet-review-count {
      display: none;
    }
  }

  .pr-attribute-group {
    &.pr-empty {
      .pr-attribute-key {
        border-radius: $default-border-radius;
      }

      .pr-attribute-value {
        display: none;
      }
    }
  }

  .pr-attribute-value-list-empty {
    line-height: 55px;
  }

  @if $clickable-histogram-enabled == true {
    .pr-attribute-pros,
    .pr-attribute-cons,
    .pr-attribute-ratings {
      &.pr-collapsed {
        .pr-attribute-value {
          display: none;
          visibility: hidden;
        }
      }
    }
    .pr-snapshot {
      .pr-attribute-group {
        position: relative;
        .pr-attribute-collapse {
          background: url('images/down-arrow.png') no-repeat;
          background-size: 15px 8px;
          position: absolute;
          top: 20px;
          right: 15px;
          width: 15px;
          height: 8px;
          transform: rotate(0.5turn);
          transition: all 0.25s ease;
        }

        &.pr-collapsed {
          .pr-attribute-collapse {
            transform: rotate(0turn);
          }
        }
      }
    }
  }
}
