.pr-review-engine-min-width-560px-max-width-690px,
.pr-review-engine-max-width-560px {
  .pr-other-attributes-list {
    .pr-other-attribute-value {
      margin-bottom: $large-spacing;
    }

    .pr-other-attribute-value:last-child {
      margin-bottom: 0;
    }
  }

  .pr-other-attributes-subratings {
    margin-bottom: $large-spacing;
  }
}
