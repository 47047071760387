@media (min-width: 1001px) {
  .pr-review-engine .pr-base-modal,
  .pr-base-modal {
    // TODO MCA: Markup bandaid
    height: auto !important;
    width: 980px !important;
    margin-left: -490px;
    top: 50% !important;
    bottom: auto !important;
    margin-top: -250px !important;

    .pr-profile-author-container {
      float: left;
      width: 30%;
      margin-right: 5%;
    }

    .pr-profile-reviews-wrapper {
      @include rounded-container;
      height: calc(100% - 41px);
      box-sizing: border-box;
      overflow: auto;
      padding: $extra-large-spacing;
      max-height: 400px;
    }

    .pr-profile-reviews-container {
      box-sizing: border-box;
      float: left;
      width: 65%;
      height: 100%;
      overflow: hidden;
    }

    .pr-review-author-date {
      float: right;
    }

    .pr-attribute-group {
      @include horizontal-attribute-group;
    }

    .pr-last {
      margin-right: 0;
    }
    .pr-review-bottom-line {
      display: inline-block;
    }

    .pr-other-attribute-additional-ratings {
      .pr-other-attribute-value,
      .pr-other-attribute-label {
        display: inline-block;
      }
    }

    .pr-other-attribute-tag-group {
      .pr-other-attribute-label {
        margin-bottom: 0;
      }
      .pr-other-attribute-value {
        margin-bottom: 5px;
      }
    }

    .pr-other-attribute-label,
    .pr-review-bottom-line {
      margin-right: $medium-spacing;
    }

    .pr-fb-follow-email-input {
      width: 250px;
    }

    .pr-fb-follow-content-wrapper > .pr-fb-follow-info {
      float: left;
      margin-right: 30+70px;
      overflow: hidden;
    }
  }
}
